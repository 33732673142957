import axios from 'axios'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../../router/routes'
import { GetAll } from '../../../../services/buyouts'
import { Buyout } from '../../../../services/buyouts/types'
import { State } from '../../../../store'
import { Table } from '../../../common/table'
import { Action, Column } from '../../../common/table/types'
import { CreateEditModal } from '../modals/create'
import { Icon, SDiv, SHR, SIconButtonDark, STitle } from '../../../../theme/commonComponents'

export const List = () => {
  const [data, setData] = useState<Buyout[]>([])
  const [page, setPage] = useState<number>(1)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [sort, setSort] = useState<{ sortBy: string; orderBy: string }>({
    sortBy: 'id',
    orderBy: 'desc',
  })
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [onLoading, setOnLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const isAdmin = useSelector<State, boolean>((state) => state.app.user.isAdmin)
  const perPage = 10
  useEffect(() => {
    try {
      GetAll((page - 1) * perPage, perPage, [], [], sort.sortBy, sort.orderBy).then((data) => {
        setData(data.data)
        setOnLoading(false)
        setTotalRecords(data.pagination.totalRecords)
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
      } else {
        console.log('unexpected error:', error)
      }
    }
  }, [page, perPage, sort, onLoading])

  const handleSort = (sortBy: string, orderBy: string) => {
    setSort({ sortBy: sortBy, orderBy: orderBy })
  }

  const handleOnView = (id: string) => {
    isAdmin
      ? navigate(ROUTES.BUYOUTS.VIEW + '/' + id)
      : navigate(ROUTES.BUYOUTS.SINGLEBUY + '/' + id)
  }

  const handleActions = (type: string, id: string) => {
    if (type == 'View') {
      handleOnView(id)
    }
  }

  const buildTable = () => {
    const columns: Column[] = [
      { title: 'Id', fieldName: 'id', hidden: true, sortable: false },
      { title: 'PWE', fieldName: 'pwe', sortable: true },
      { title: 'Status', fieldName: 'status', sortable: true },
      { title: 'Received quantity', fieldName: 'receivedQuantity', sortable: true },
      { title: 'Reception date', fieldName: 'receptionDate', sortable: true },
      { title: 'Buy date', fieldName: 'buyDate', sortable: true },
    ]

    const actions: Action[] = [{ label: 'View', fieldName: 'id', type: 'View' }]

    return (
      <Table
        columns={columns}
        data={data}
        actions={actions}
        page={page}
        perPage={perPage}
        setPage={setPage}
        totalRecords={totalRecords}
        handleActions={handleActions}
        handleSort={handleSort}
      />
    )
  }

  const buildModal = () => {
    return (
      <CreateEditModal
        show={showCreateModal}
        setShow={setShowCreateModal}
        setOnLoading={setOnLoading}
      />
    )
  }

  return (
    <>
      <SDiv className='d-flex flex-column p-4 pt-3 mb-5 h-100'>
        <div className='row mb-3'>
          <div className='col-12'>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <STitle>Buyouts</STitle>
              {isAdmin ? (
                <SIconButtonDark title='Add Buyout' className='btn p-0 m-0' onClick={() => setShowCreateModal(true)}>
                  <Icon className='bi-plus-square'></Icon>
                </SIconButtonDark>
              ) : ('')}
            </div>
            <SHR />
          </div>
        </div>
        <div className='mt-4 flex-fill bg-white rounded p-3'>
          {buildTable()}
        </div>
        {showCreateModal ? buildModal() : ''}
      </SDiv>
    </>
  )
}
