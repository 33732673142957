import { useAuth0 } from '@auth0/auth0-react';
import { SButton } from '../../../theme/commonComponents';
import { SButtonOutline } from '../../../theme/commonComponents';
import { LoginBox } from '../../../theme/commonComponents';
import { SLabel } from '../../../theme/commonComponents';
import logo from '../../../assets/fox_logo.png';



export const Login = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = async () => {
    await loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  };

  return (
    <LoginBox>
      <div className="jumbotron d-flex align-items-center min-vh-100" >
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-2"></div>
            <div className="col-lg-6 col-md-8 login-box p-5">
              <div className="row">
                <div className="col-lg-3 col-md-2"></div>
                <div className="col-lg-6 col-md-8">
                  <div className="row mb-4">
                    <img width='30%' src={logo} alt="Logo" />
                  </div>
                  <div className="row mb-5">
                    <SButton className="p-3" onClick={() => handleSignUp()}>SIGN UP</SButton>
                  </div>
                  <div className="row">
                    <SLabel className="mb-2">Have an account?</SLabel>
                  </div>
                  <div className="row">
                    <SButtonOutline onClick={() => loginWithRedirect()}>SIGN IN</SButtonOutline>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoginBox>
  )
}