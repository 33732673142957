import * as yup from 'yup'
import { isValid } from '../../../../../utils/validators/driversLicenseValidator/validator'
import { TestContext } from 'yup'

export const formDataSchema = yup
  .object({
    externalId: yup.string().required(),
    firstName: yup.string().required().label('First name'),
    lastName: yup.string().required().label('Last name'),
    dateOfBirth: yup.string().required().label('Birth date'),
    address: yup.string().required().label('Address'),
    city: yup.string().required().label('City'),
    state: yup.string().required().label('State'),
    zipCode: yup
      .string()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, { message: 'Invalid ZIP/Postal code format' })
      .required(),
    phone: yup.string().required().label('Phone'),
    email: yup.string().email().required().label('Email'),
    paypalEmail: yup.string().email().label('Paypal Email'),
    issuer: yup.string().required().label('Issuer'),
    driverLicenseNumber: yup
      .string()
      .test('empty', 'Driver\'s License number is required', (value) => (value?.length ?? 0) > 0)
      .test('issuer', 'Select issuer first', (value, context: TestContext) => {
        const contextGeneric = context as unknown as { from: { value: Record<string, unknown> }[] }
        const { issuer } = contextGeneric.from[0].value
        if (issuer === undefined || issuer === '') {
          return false
        } else{
          return true
        }
      })
      .test('valid', 'Invalid driver\'s license number', (value, context: TestContext) => {
        const contextGeneric = context as unknown as { from: { value: Record<string, unknown> }[] }
        const { issuer } = contextGeneric.from[0].value
        if (issuer === undefined || issuer === '') {
          return false
        }
        return isValid(value ?? '', issuer as string)
      })
      .required()
      .label('Driver\'s license number'),
    expirationDate: yup.string().required().label('Expiration date'),
    acceptTermsAndConditions: yup
      .boolean()
      .oneOf([true], 'Terms & Conditions must be accecpted')
      .required('Terms & Conditions must be accecpted'),
  })
  .required()

export interface FormData extends yup.InferType<typeof formDataSchema> { }
