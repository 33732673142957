import client from '../api/configs'
import { PaginatedData } from '../common/types'
import { mapToChildList } from './mapper'
import { Child } from './types'

export const GetAll = async (
  offset?: number,
  limit?: number,
  filterKey?: Array<string>,
  filterValue?: Array<string>,
): Promise<PaginatedData<Child>> => {
  const res = await client.getChildren({ offset, limit, filterKey, filterValue })
  return {
    data: mapToChildList(res.data ?? []),
    pagination: {
      totalRecords: res.pagination.total_records,
      page: res.pagination.page,
      perPage: res.pagination.per_page,
    },
  }
}

export const Delete = async (id: string): Promise<void> => {
  await client.deleteChild({ id })
}
