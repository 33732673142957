import * as yup from 'yup'

export const formDataSchema = yup
  .object({
    name: yup.string().required().label('Name'),
    type: yup.string().required().label('Type'),
    l1_category_name: yup.string().label('l1_category'),
    l1_category_id: yup.string().label('l1_category'),
    l2_category_name: yup.string().label('l2_category'),
    l2_category_id: yup.string().label('l2_category'),
  })
  .required()

export interface FormData extends yup.InferType<typeof formDataSchema> {}
