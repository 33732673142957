import * as yup from 'yup'
import { parseNumber } from '../../../../../../utils/parseNumber'

export interface FormData {
  userId: string
  buyoutId: string
  // cashOffer: string
  storeCreditOffer: string
  picture: FileList
  images: Image[]
  sizeTag: string
  brandTag: string
  categoryTag: string
  genderTag: string
  conditionTag: string
  colorTags: string[]
  patternTag: string
}

export type Image = {
  imageUrl: string
  imageBlobId: string
}

export const formDataSchema = yup
  .object({
    userId: yup.string().required(),
    buyoutId: yup.string().required(),
    /* cashOffer: yup
      .mixed()
      .transform((value) => parseNumber(value))
      .label('Cash offer'), */
    storeCreditOffer: yup
      .mixed()
      .transform((value) => parseNumber(value))
      .label('Store credit offer'),
    /*TODO: DO NOT REMOVE, TO BE IMPLEMENTED WHEN BE IS READY*/
    /* images: yup.array().of(
      yup.object().shape({
        imageUrl: yup.string().label('Picture'),
        imageBlobId: yup.string().label('Picture'),
      }),
    ), */
    sizeTag: yup.string().required().label('Size'),
    brandTag: yup.string().required().label('Brand'),
    categoryTag: yup.string().required().label('Category'),
    genderTag: yup.string().label('Gender'),
    conditionTag: yup.string().required().label('Condition'),
    colorTags: yup.array().label('Colors'),
    patternTag: yup.string().label('Pattern'),
  })
  .required()
