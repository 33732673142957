
import { ThemeProvider } from 'styled-components'
import theme from './theme/theme';
import { store } from './store';
import { Provider } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './components/common/generic/loading';
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';
import { Login } from './components/common/login';
import { useSetToken } from './hooks/useSetToken';
import { Home } from './components/modules/home';

export const App = () => {
  return (
    <Auth0ProviderWithNavigate>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Content />
        </Provider>
      </ThemeProvider>
    </Auth0ProviderWithNavigate>
  )
}

const Content = () => {
  const { isLoading, error, isAuthenticated } = useAuth0()
  useSetToken()

  if (error) {
    return <div>Oops... {error.message}</div>
  }

  if (isLoading) {
    return <Loading />
  }

  if (!isAuthenticated) {
    return <Login />
  }

  return <Home />
}
