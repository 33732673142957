import { FormData as BuyoutFormData } from '../../components/modules/buyouts/modals/create/validation/schema'
import { FormData as RejecitonFormData } from '../../components/modules/buyouts/view/singleBuy/rejection/validation/schema'
import { User } from '../account/types'
import { AddBuyoutDTO, BuyoutDTO, RejectDTO, RejectionDTO, SelectedOfferType } from '../generated'
import { Buyout, Rejection, UserOption } from './types'
import * as jsonpatch from 'fast-json-patch'

export const buyoutStatus = {
  NOT_STARTED: 'Not started',
  IN_REVIEW: 'In Review',
  CUSTOMER_REJECTED: 'Customer rejected',
  CUSTOMER_APPROVED: 'Customer approved',
  SUBMITTED_FOR_CUSTOMER_APPROVAL: 'Awaiting Approval',
  COMPLETED: 'Completed',
}

export const mapToBuyout = (data: BuyoutDTO): Buyout => {
  return {
    id: data.id ?? '',
    pwe: data.pwe ?? '',
    buyDate: data.buy_date === undefined ? undefined : new Date(data.buy_date),
    receivedQuantity: data.received_quantity ?? 0,
    receptionDate: data.reception_date === undefined ? undefined : new Date(data.reception_date),
    rejectionId: data.rejection ? data.rejection.rejection_type.id : '',
    rejection: data.rejection ? mapToRejection(data.rejection) : undefined,
    rejectionText: data.rejection_text ?? '',
    status: mapStatus(data.status ?? ''),
    subtotal: data.subtotal ?? 0,
    user: {
      address: data.user?.address ?? '',
      email: data.user?.email ?? '',
      firstName: data.user?.first_name ?? '',
      lastName: data.user?.last_name ?? '',
      id: data.user?.id ?? '',
      phone: data.user?.phone ?? '',
      city: data.user?.city ?? '',
      state: data.user?.state ?? '',
      zipCode: data.user?.zip_code ?? '',
    },
    selectedOfferType: data.selected_offer_type ?? '',
  }
}

export const mapToBuyoutDTO = (data: Buyout): BuyoutDTO => {
  return {
    id: data.id ?? '',
    pwe: data.pwe,
    buy_date: data.buyDate !== undefined ? data.buyDate?.toUTCString() : undefined,
    received_quantity: data.receivedQuantity,
    reception_date: data.receptionDate !== undefined ? data.receptionDate.toUTCString() : undefined,
    rejection: data.rejection ? mapToRejectionDTO(data.rejection) : ({} as RejectionDTO),
    rejection_text: data.rejectionText,
    status: mapStatus(data.status),
    user: data.user,
    selected_offer_type:
            data.selectedOfferType == SelectedOfferType.CASH
              ? SelectedOfferType.CASH
              : SelectedOfferType.STORE_CREDIT,
  }
}

export const mapToBuyoutList = (data: BuyoutDTO[]): Buyout[] => {
  let list: Buyout[] = []

  data.forEach((element) => {
    list.push(mapToBuyout(element))
  })

  return list
}

export const mapToRejection = (data: RejectionDTO): Rejection | undefined => {
  if (Object.keys(data).length === 0 || Object.keys(data.rejection_type).length === 0) {
    return undefined
  }
  return {
    type: {
      id: data.rejection_type.id,
      description: data.rejection_type.description,
    },
    text: data.rejection_description ?? '',
  }
}

export const mapToRejectionDTO = (data: Rejection): RejectionDTO => {
  if (Object.keys(data).length === 0) {
    return {} as RejectionDTO
  }

  return {
    rejection_type: {
      id: data !== undefined ? data.type.id : '',
      description: data.type.description,
    },
    rejection_description: data.text,
  }
}

export const mapToCreateBuyoutRequest = (data: BuyoutFormData): AddBuyoutDTO => {
  return {
    user_id: data.userId,
    received_quantity: data.receivedQuantity,
    reception_date: data.receptionDate.toDateString(),
  }
}

export const mapToPatchBuyoutRequest = (buyout: Buyout, data: BuyoutFormData): string => {
  var observer = jsonpatch.observe<Buyout>(buyout)

  if (buyout.receivedQuantity != data.receivedQuantity) {
    buyout.receivedQuantity = data.receivedQuantity
  }

  if (buyout.receptionDate != data.receptionDate) {
    buyout.receptionDate = data.receptionDate
  }

  if (buyout.user !== undefined && buyout.user?.id != data.userId) {
    buyout.user.id = data.userId
  }

  var patch = jsonpatch.generate(observer)
  return JSON.stringify(patch)
}

export const mapToBuyoutRejectionRequest = (data: RejecitonFormData): RejectDTO => {
  return {
    rejection_id: data.rejectionId,
    rejection_description: data.rejectionText,
  }
}

export const mapStatus = (status: string): string => {
  let newStatus = ''
  switch (status) {
  case 'NOT_STARTED':
    newStatus = 'Not started'
    break
  case 'IN_REVIEW':
    newStatus = 'In Review'
    break
  case 'CUSTOMER_REJECTED':
    newStatus = 'Customer rejected'
    break
  case 'CUSTOMER_APPROVED':
    newStatus = 'Customer approved'
    break
  case 'SUBMITTED_FOR_CUSTOMER_APPROVAL':
    newStatus = 'Awaiting Approval'
    break
  case 'COMPLETED':
    newStatus = 'Completed'
    break
  default:
    break
  }

  return newStatus
}

export const mapUserToUserOption = (data: User[]): UserOption[] => {
  return data.map((elem) => ({ value: elem.id, label: getUserLabel(elem) }))
}

export const getUserLabel = (elem: User): string => {
  return elem.firstName + ' ' + elem.lastName + ' [' + elem.email + ']'
}
