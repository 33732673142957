import loading from '../../../assets/LoadingFox.gif';
import { SAltDiv } from '../../../theme/commonComponents';


const Loading = () => (
  <SAltDiv className='vh-100'>
    <div className='d-flex h-100 justify-content-center align-items-center'>
      <img src={loading} width='15%' alt="Loading" />
    </div>
  </SAltDiv>
);

export default Loading;
