import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { formDataSchema, FormData } from './validation/schema'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { GetRejectionOptions, RejectBuyout } from '../../../../../../services/buyouts'
import { RejectionTypeDTO } from '../../../../../../services/generated'
import axios from 'axios'
import { mapToBuyoutRejectionRequest } from '../../../../../../services/buyouts/mapper'
import { Buyout } from '../../../../../../services/buyouts/types'
import { DisplayError } from '../../../../../common/error'
import { Popup } from '../../../../../common/modal'
import { SButton, SButtonDanger, SLabel } from '../../../../../../theme/commonComponents'

type Props = {
  buyout: Buyout
  handleCancelDenyBuyout: () => void
  setOnLoading: React.Dispatch<React.SetStateAction<boolean>>
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

export const RejectionBuyoutModal = ({ show, setShow, buyout, handleCancelDenyBuyout, setOnLoading }: Props) => {
  const [options, setOptions] = useState<RejectionTypeDTO[]>([])
  const [showTextfield, setShowTextField] = useState<boolean>(false)
  const [rejectionOptionText, setRejectionOptionText] = useState<string>('nan')
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(formDataSchema(rejectionOptionText)),
    mode: 'onChange',
  })

  const handleClose = () => {
    setShow(false)
  }

  useEffect((): void => {
    setValue('buyoutId', buyout.id);
  }, [])

  const getRejectionOptions = (): void => {
    try {
      GetRejectionOptions().then((data) => {
        setOptions(data)
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
      } else {
        console.log('unexpected error:', error)
      }
    }
  }

  useEffect((): void => {
    getRejectionOptions()
  }, [])

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const value = event.target.value
    setValue('rejectionId', value)
    const text = event.target.selectedOptions[0].text
    setShowTextField(text === 'Other')
    setRejectionOptionText(text)
  }

  const onSubmit = (data: FormData): string | undefined => {
    const request = mapToBuyoutRejectionRequest(data)
    try {
      RejectBuyout(buyout?.id ?? '', request).then(() => {
        console.log('Success')
        reset()
        setOnLoading(true)
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
        return error.message
      } else {
        console.log('unexpected error:', error)
        return 'An unexpected error occured'
      }
    }
  }

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='row mb-3'>
          <div className='col-12'>
            <SLabel htmlFor='rejecitonId'>
              Reason
            </SLabel>
            <select {...register('rejectionId')}
              id="rejectionId"
              className="form-select"
              onChange={(e): void => onChange(e)} defaultValue={'0'}>
              <option key={0} value={'0'}>Select</option>
              {options.map((value) => <option key={value.id} value={value.id}>{value.description}</option>)}
            </select>
            <DisplayError field={errors?.rejectionId} />
          </div>
        </div>
        {showTextfield ? (
          <div className='row'>
            <div className='col-12'>
              <SLabel htmlFor='rejectionText' >
                Details
              </SLabel>
              <textarea
                className='form-control form-control-sm'
                id='rejectionText'
                {...register('rejectionText')}
              />
              <DisplayError field={errors?.rejectionText} />
            </div>
          </div>
        ) : (
          ''
        )}
        <div className='d-flex justify-content-end mt-5'>
          <SButtonDanger className='me-3' onClick={handleCancelDenyBuyout}>
            Close
          </SButtonDanger>
          <SButton type='submit'>
            Submit
          </SButton>
        </div>
      </form>
    )
  }
  return (
    <>
      <Popup
        show={show}
        backdrop='static'
        title='Rejection'
        handleClose={handleClose}
        content={renderForm()}
      />
    </>
  )
}
