import { ReactNode, useState } from 'react'
import { Action, Column, ImageColumnItem } from './types'
import moment from 'moment'
import { Icon, SButtonOutlineAlt, STableDiv } from '../../../theme/commonComponents'

interface Props<T extends object> {
  columns: Column[]
  data: T[]
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  perPage: number
  totalRecords: number
  hidePagination?: boolean
  actions?: Action[]
  handleActions?: (type: string, id: string) => void
  handleSort?: (sortBy: string, orderBy: string) => void
}

interface sorting {
  sortField: string
  order: string
}

export const Table = <T extends object>({
  columns,
  data,
  handleSort,
  hidePagination,
  page,
  perPage,
  totalRecords,
  actions,
  handleActions,
  setPage,
}: Props<T>) => {
  const [sort, setSort] = useState<sorting>({ sortField: '', order: 'asc' })

  const handleSorting = (sortField: string) => {
    let order = sort.order === 'asc' ? 'desc' : sort.order == 'desc' ? 'asc' : ''
    setSort({ sortField: sortField, order: order })
    handleSort?.(sortField, order)
  }

  const buildHeaders = () => {
    return (
      <tr>
        {actions ? <th scope='col' className='col-1'></th> : ''}
        {columns.map(({ title, fieldName, sortable, width, hidden }, index) => {
          const sortClass = sortable
            ? sort.sortField === fieldName && sort.order === 'asc'
              ? 'bi bi-sort-up'
              : sort.sortField === fieldName && sort.order === 'desc'
                ? 'bi bi-sort-down'
                : 'bi bi-filter'
            : ''
          return (
            <th
              key={index}
              scope='col'
              onClick={sortable && handleSort ? () => handleSorting(fieldName) : undefined}
              style={{ width: `${width}`, display: `${hidden ? 'none' : 'table-cell'}` , cursor:'pointer'}}
            >
              {title}
              <i className={sortClass}></i>
            </th>
          )
        })}
      </tr>
    )
  }

  const buildRow = (element: T) => {
    let entries: ReactNode[] = []

    if (actions) {
      let actionEntries: ReactNode[] = []
      actions.forEach((item, index) => {
        const entry = Object.entries(element).find(([key]) => key === item.fieldName)
        let value = entry !== undefined ? entry[1] : ''
        let icon = ''
        switch (item.type) {
        case 'View':
          icon = 'bi bi-eye'
          break
        case 'Edit':
          icon = 'bi bi-pen'
          break
        case 'Delete':
          icon = 'bi bi-trash3'
          break
        default:
          break
        }
        actionEntries.push(
          <SButtonOutlineAlt
            className='p-0 px-1 me-2'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title={item.type}
            key={index}
            onClick={() => handleActions?.(item.type, value)}
          >
            <Icon className={icon}></Icon>
          </SButtonOutlineAlt>,
        )
      })

      entries.push(<td className='col-1' key={0}>{actionEntries.map((item) => item)}</td>)
    }

    columns.forEach((item, index) => {
      if ((item.fieldName as keyof typeof element) in element) {
        const entry = Object.entries(element).find(([key]) => key === item.fieldName)
        let value = entry !== undefined ? entry[1] : ''

        if (Object.prototype.toString.call(value) === '[object Date]') {
          value = moment(value as Date).format('MM-DD-YYYY')
        }

        if (item.isImage === true && typeof String) {
          value = <img style={{ width: '25%' }} src={value} />
        }

        if (
          item.isImage === true &&
          typeof Array<ImageColumnItem> &&
          (value as Array<ImageColumnItem>).length > 0
        ) {
          let image = value[0] as ImageColumnItem
          value = <img style={{ width: '25%' }} src={image.imageUrl} />
        }

        if(item.dollarSymbol){
          value='$'+value;
        }

        entries.push(
          <td
            style={{ display: `${item.hidden ? 'none' : 'table-cell'}` }}
            key={index + 1}
            onClick={() => item.onClick?.(value)}
          >
            {value}
          </td>,
        )
      }
    })

    return entries
  }

  const buildContents = () => {
    return data.map((element, index) => {
      return <tr key={index}>{buildRow(element)}</tr>
    })
  }

  const handlePrevious = () => {
    setPage(page - 1)
  }

  const handleNext = () => {
    setPage(page + 1)
  }

  return (
    <div className='table-responsive'>
      <STableDiv
        className='table table-hover'
        style={{ overflow: 'hidden' }}
      >
        <thead>{buildHeaders()}</thead>
        <tbody style={{ borderColor: 'inherit' }}>{buildContents()}</tbody>
      </STableDiv>

      {hidePagination ? (
        ''
      ) : (
        <nav aria-label='Page navigation' style={{ display: 'flex', justifyContent: 'end' }}>
          <ul className='pagination'>
            <li
              className={`page-item ${page <= 1 ? 'disabled' : ''}`}
              onClick={page <= 1 ? () => {} : handlePrevious}
            >
              <a className='page-link'>Previous</a>
            </li>
            <li className='page-item'>
              <a className='page-link'>
                {page} of {Math.ceil(totalRecords / perPage)}
              </a>
            </li>
            <li
              className={`page-item ${perPage * page >= totalRecords ? 'disabled' : ''}`}
              onClick={perPage * page >= totalRecords ? () => {} : handleNext}
            >
              <a className='page-link'>Next</a>
            </li>
          </ul>
        </nav>
      )}
    </div>
  )
}
