import * as yup from 'yup'

export const formDataSchema = yup
  .object({
    userId: yup.string().required(),
    firstName: yup.string()
      .required()
      .label('First name'),
    birthday: yup.date()
      .transform((value) => (isNaN(value) || value === null || value === undefined) ? null : value)
      .required()
      .label('Birthday')
  })
  .required()

export interface FormData extends yup.InferType<typeof formDataSchema> { }