/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptDTO } from '../models/AcceptDTO';
import type { AddBuyoutDTO } from '../models/AddBuyoutDTO';
import type { AddCategoryDTO } from '../models/AddCategoryDTO';
import type { BuyoutDTO } from '../models/BuyoutDTO';
import type { CategoryLevelDTO } from '../models/CategoryLevelDTO';
import type { ChildDTO } from '../models/ChildDTO';
import type { Id } from '../models/Id';
import type { ImageDTO } from '../models/ImageDTO';
import type { ItemDTO } from '../models/ItemDTO';
import type { NotificationDTO } from '../models/NotificationDTO';
import type { NotificationListItemDTO } from '../models/NotificationListItemDTO';
import type { NotificationTypeDTO } from '../models/NotificationTypeDTO';
import type { PaginationDTO } from '../models/PaginationDTO';
import type { PatchItemTagsDTO } from '../models/PatchItemTagsDTO';
import type { RejectDTO } from '../models/RejectDTO';
import type { RejectionTypeDTO } from '../models/RejectionTypeDTO';
import type { SortOrderDTO } from '../models/SortOrderDTO';
import type { TagDTO } from '../models/TagDTO';
import type { TemplateDTO } from '../models/TemplateDTO';
import type { UserDTO } from '../models/UserDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a user
     * @returns UserDTO The newly created user
     * @throws ApiError
     */
    public addUser({
requestBody,
}: {
/**
 * User payload
 */
requestBody?: UserDTO,
}): CancelablePromise<UserDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a list of users
     * @returns any A list of users
     * @throws ApiError
     */
    public getUsers({
offset,
limit,
filterKey,
filterValue,
sortKey,
sortOrder,
}: {
/**
 * The number of items to skip before starting to collect the result set
 */
offset?: number,
/**
 * The numbers of items to return
 */
limit?: number,
/**
 * name of the field to filter by
 */
filterKey?: Array<string>,
/**
 * value of the field to filter with
 */
filterValue?: Array<string>,
sortKey?: string,
sortOrder?: SortOrderDTO,
}): CancelablePromise<{
data: Array<UserDTO>;
pagination: PaginationDTO;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users',
            query: {
                'offset': offset,
                'limit': limit,
                'filter-key': filterKey,
                'filter-value': filterValue,
                'sort-key': sortKey,
                'sort-order': sortOrder,
            },
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Search list of users
     * @returns UserDTO A list of users
     * @throws ApiError
     */
    public searchUsers({
offset,
limit,
term,
filterKey,
filterValue,
}: {
/**
 * The number of items to skip before starting to collect the result set
 */
offset: number,
/**
 * The numbers of items to return
 */
limit: number,
/**
 * value of the field to search with
 */
term: string,
/**
 * name of the field to filter by
 */
filterKey?: Array<string>,
/**
 * value of the field to filter with
 */
filterValue?: Array<string>,
}): CancelablePromise<Array<UserDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/search',
            query: {
                'offset': offset,
                'limit': limit,
                'term': term,
                'filter-key': filterKey,
                'filter-value': filterValue,
            },
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a user
     * @returns UserDTO A user
     * @throws ApiError
     */
    public getUser({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<UserDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `No user found for the provided \`Id\``,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Patch a user
     * Body schema should follow format RFC 6902 (application/json-patch+json)
     * @returns any Success patching a user
     * @throws ApiError
     */
    public updateUser({
id,
requestBody,
}: {
/**
 * The unique identifier
 */
id: Id,
requestBody?: any,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `No user found for the provided \`Id\``,
                409: `Conflict detected during update`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Delete a user
     * @returns any Success deleting a user
     * @throws ApiError
     */
    public deleteUser({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `No user found for the provided \`Id\``,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Create a Child
     * @returns ChildDTO The newly created Child
     * @throws ApiError
     */
    public addUserChild({
id,
requestBody,
}: {
/**
 * The unique identifier
 */
id: Id,
/**
 * Child payload
 */
requestBody?: ChildDTO,
}): CancelablePromise<ChildDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/{id}/children',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a list of children
     * @returns ChildDTO A list of children
     * @throws ApiError
     */
    public getUserChildren({
id,
offset,
limit,
filterKey,
filterValue,
sortKey,
sortOrder,
}: {
/**
 * The unique identifier
 */
id: Id,
/**
 * The number of children to skip before starting to collect the result set
 */
offset?: number,
/**
 * The numbers of children to return
 */
limit?: number,
/**
 * name of the field to filter by
 */
filterKey?: Array<string>,
/**
 * value of the field to filter with
 */
filterValue?: Array<string>,
sortKey?: string,
sortOrder?: SortOrderDTO,
}): CancelablePromise<Array<ChildDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{id}/children',
            path: {
                'id': id,
            },
            query: {
                'offset': offset,
                'limit': limit,
                'filter-key': filterKey,
                'filter-value': filterValue,
                'sort-key': sortKey,
                'sort-order': sortOrder,
            },
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a list of notifications
     * @returns NotificationListItemDTO A list of notifications
     * @throws ApiError
     */
    public getUserNotifications({
id,
offset,
limit,
filterKey,
filterValue,
sortKey,
sortOrder,
}: {
/**
 * The unique identifier
 */
id: Id,
/**
 * The number of items to skip before starting to collect the result set
 */
offset?: number,
/**
 * The numbers of items to return
 */
limit?: number,
/**
 * name of the field to filter by
 */
filterKey?: Array<string>,
/**
 * value of the field to filter with
 */
filterValue?: Array<string>,
sortKey?: string,
sortOrder?: SortOrderDTO,
}): CancelablePromise<Array<NotificationListItemDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/{id}/notifications',
            path: {
                'id': id,
            },
            query: {
                'offset': offset,
                'limit': limit,
                'filter-key': filterKey,
                'filter-value': filterValue,
                'sort-key': sortKey,
                'sort-order': sortOrder,
            },
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Upsert the lightspeed-id of the user
     * @returns any Id updated successfuly
     * @throws ApiError
     */
    public setUserLightspeedId({
id,
lightspeedId,
}: {
/**
 * The unique identifier
 */
id: Id,
/**
 * The lightspeed id
 */
lightspeedId: string,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/{id}/set-lightspeed-id',
            path: {
                'id': id,
            },
            query: {
                'lightspeed_id': lightspeedId,
            },
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a list of children
     * @returns any A list of children
     * @throws ApiError
     */
    public getChildren({
offset,
limit,
filterKey,
filterValue,
sortKey,
sortOrder,
}: {
/**
 * The number of items to skip before starting to collect the result set
 */
offset?: number,
/**
 * The numbers of items to return
 */
limit?: number,
/**
 * name of the field to filter by
 */
filterKey?: Array<string>,
/**
 * value of the field to filter with
 */
filterValue?: Array<string>,
sortKey?: string,
sortOrder?: SortOrderDTO,
}): CancelablePromise<{
data: Array<ChildDTO>;
pagination: PaginationDTO;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/children',
            query: {
                'offset': offset,
                'limit': limit,
                'filter-key': filterKey,
                'filter-value': filterValue,
                'sort-key': sortKey,
                'sort-order': sortOrder,
            },
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a child
     * @returns ChildDTO A child
     * @throws ApiError
     */
    public getChild({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<ChildDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/children/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `No item found for the provided \`Id\``,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Patch a child
     * Body schema should follow format RFC 6902 (application/json-patch+json)
     * @returns any Success patching a child
     * @throws ApiError
     */
    public updateChild({
id,
requestBody,
}: {
/**
 * The unique identifier
 */
id: Id,
requestBody?: any,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/children/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `No child found for the provided \`Id\``,
                409: `Conflict detected during update`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Delete a child
     * @returns any Success deleting a child
     * @throws ApiError
     */
    public deleteChild({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/children/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `No child found for the provided \`Id\``,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Create a tag
     * @returns TagDTO The newly created tag
     * @throws ApiError
     */
    public addTag({
requestBody,
}: {
/**
 * Tag payload
 */
requestBody?: TagDTO,
}): CancelablePromise<TagDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tags',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a list of tags
     * @returns any A list of tags
     * @throws ApiError
     */
    public getTags({
offset,
limit,
filterKey,
filterValue,
sortKey,
sortOrder,
}: {
/**
 * The number of items to skip before starting to collect the result set
 */
offset?: number,
/**
 * The numbers of items to return
 */
limit?: number,
/**
 * name of the field to filter by
 */
filterKey?: Array<string>,
/**
 * value of the field to filter with
 */
filterValue?: Array<string>,
sortKey?: string,
sortOrder?: SortOrderDTO,
}): CancelablePromise<{
data: Array<TagDTO>;
pagination: PaginationDTO;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tags',
            query: {
                'offset': offset,
                'limit': limit,
                'filter-key': filterKey,
                'filter-value': filterValue,
                'sort-key': sortKey,
                'sort-order': sortOrder,
            },
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Search list of tags
     * @returns TagDTO A list of tags
     * @throws ApiError
     */
    public searchTags({
offset,
limit,
searchKey,
searchValue,
filterKey,
filterValue,
}: {
/**
 * The number of items to skip before starting to collect the result set
 */
offset: number,
/**
 * The numbers of items to return
 */
limit: number,
/**
 * name of the field to search by
 */
searchKey: string,
/**
 * value of the field to search with
 */
searchValue: string,
/**
 * name of the field to filter by
 */
filterKey?: Array<string>,
/**
 * value of the field to filter with
 */
filterValue?: Array<string>,
}): CancelablePromise<Array<TagDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tags/search',
            query: {
                'offset': offset,
                'limit': limit,
                'search-key': searchKey,
                'search-value': searchValue,
                'filter-key': filterKey,
                'filter-value': filterValue,
            },
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a tag
     * @returns TagDTO A tag
     * @throws ApiError
     */
    public getTag({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<TagDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tags/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `No tag found for the provided \`Id\``,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Patch a tag
     * Body schema should follow format RFC 6902 (application/json-patch+json)
     * @returns any Success patching a tag
     * @throws ApiError
     */
    public updateTag({
id,
requestBody,
}: {
/**
 * The unique identifier
 */
id: Id,
requestBody?: any,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/tags/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `No tag found for the provided \`Id\``,
                409: `Conflict detected during update`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Delete a tag
     * @returns any Success deleting a tag
     * @throws ApiError
     */
    public deleteTag({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/tags/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `No tag found for the provided \`Id\``,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch items with the associated tag id
     * @returns ItemDTO List of items
     * @throws ApiError
     */
    public getTagItems({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<Array<ItemDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tags/{id}/items',
            path: {
                'id': id,
            },
            errors: {
                404: `No tag found for the provided \`Id\``,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Create a category
     * @returns TagDTO The newly created category
     * @throws ApiError
     */
    public addCategory({
requestBody,
}: {
/**
 * Category payload
 */
requestBody?: AddCategoryDTO,
}): CancelablePromise<TagDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/categories',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a list of categories
     * @returns CategoryLevelDTO A list of tags
     * @throws ApiError
     */
    public getCategories({
searchName,
parentId,
offset,
limit,
}: {
/**
 * Parent category id
 */
searchName?: string,
/**
 * Parent category id
 */
parentId?: Id,
/**
 * The number of items to skip before starting to collect the result set
 */
offset?: number,
/**
 * The numbers of items to return
 */
limit?: number,
}): CancelablePromise<Array<CategoryLevelDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/categories',
            query: {
                'search-name': searchName,
                'parent-id': parentId,
                'offset': offset,
                'limit': limit,
            },
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Create a buyout
     * @returns BuyoutDTO The newly created buyout
     * @throws ApiError
     */
    public addBuyout({
requestBody,
}: {
/**
 * Buyout payload
 */
requestBody?: AddBuyoutDTO,
}): CancelablePromise<BuyoutDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/buyouts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a list of buyouts
     * @returns any A list of buyouts
     * @throws ApiError
     */
    public getBuyouts({
offset,
limit,
filterKey,
filterValue,
sortKey,
sortOrder,
}: {
/**
 * The number of items to skip before starting to collect the result set
 */
offset?: number,
/**
 * The numbers of items to return
 */
limit?: number,
/**
 * name of the field to filter by
 */
filterKey?: Array<string>,
/**
 * value of the field to filter with
 */
filterValue?: Array<string>,
sortKey?: string,
sortOrder?: SortOrderDTO,
}): CancelablePromise<{
data: Array<BuyoutDTO>;
pagination: PaginationDTO;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/buyouts',
            query: {
                'offset': offset,
                'limit': limit,
                'filter-key': filterKey,
                'filter-value': filterValue,
                'sort-key': sortKey,
                'sort-order': sortOrder,
            },
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a buyout
     * @returns BuyoutDTO A buyout
     * @throws ApiError
     */
    public getBuyout({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<BuyoutDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/buyouts/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `No buyout found for the provided \`Id\``,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Patch a buyout
     * Body schema should follow format RFC 6902 (application/json-patch+json)
     * @returns any Success patching a buyout
     * @throws ApiError
     */
    public updateBuyout({
id,
requestBody,
}: {
/**
 * The unique identifier
 */
id: Id,
requestBody?: any,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/buyouts/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `No buyout found for the provided \`Id\``,
                409: `Conflict detected during update`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Delete a buyout
     * @returns any Success deleting a buyout
     * @throws ApiError
     */
    public deleteBuyout({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/buyouts/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `No buyout found for the provided \`Id\``,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Create a item
     * @returns ItemDTO The newly created item
     * @throws ApiError
     */
    public addBuyoutItem({
id,
requestBody,
}: {
/**
 * The unique identifier
 */
id: Id,
/**
 * Item payload
 */
requestBody?: ItemDTO,
}): CancelablePromise<ItemDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/buyouts/{id}/items',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Conflict detected during update`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a list of items
     * @returns ItemDTO A list of items
     * @throws ApiError
     */
    public getBuyoutItems({
id,
offset,
limit,
filterKey,
filterValue,
sortKey,
sortOrder,
}: {
/**
 * The unique identifier
 */
id: Id,
/**
 * The number of items to skip before starting to collect the result set
 */
offset?: number,
/**
 * The numbers of items to return
 */
limit?: number,
/**
 * name of the field to filter by
 */
filterKey?: Array<string>,
/**
 * value of the field to filter with
 */
filterValue?: Array<string>,
sortKey?: string,
sortOrder?: SortOrderDTO,
}): CancelablePromise<Array<ItemDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/buyouts/{id}/items',
            path: {
                'id': id,
            },
            query: {
                'offset': offset,
                'limit': limit,
                'filter-key': filterKey,
                'filter-value': filterValue,
                'sort-key': sortKey,
                'sort-order': sortOrder,
            },
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Reject buyout offer
     * @returns any Buyout rejected successfuly
     * @throws ApiError
     */
    public rejectBuyout({
id,
requestBody,
}: {
/**
 * The unique identifier
 */
id: Id,
/**
 * Rejection payload
 */
requestBody?: RejectDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/buyouts/{id}/reject',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Detected invalid status during update`,
                409: `Conflict detected during update`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Accept buyout offer
     * @returns any Buyout rejected successfuly
     * @throws ApiError
     */
    public acceptBuyout({
id,
requestBody,
}: {
/**
 * The unique identifier
 */
id: Id,
/**
 * Accept payload
 */
requestBody?: AcceptDTO,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/buyouts/{id}/accept',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Detected invalid status during update`,
                409: `Conflict detected during update`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Buyout ready to review
     * @returns any Buyout state moved to in-review successfuly
     * @throws ApiError
     */
    public reviewBuyout({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/buyouts/{id}/review',
            path: {
                'id': id,
            },
            errors: {
                404: `Detected invalid status during update`,
                409: `Conflict detected during update`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Submit Buyout to user
     * @returns any Buyout submitted successfuly
     * @throws ApiError
     */
    public submitBuyout({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/buyouts/{id}/submit',
            path: {
                'id': id,
            },
            errors: {
                404: `Detected invalid status during update`,
                409: `Conflict detected during update`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Complete Buyout
     * @returns any Buyout completted successfuly
     * @throws ApiError
     */
    public completeBuyout({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/buyouts/{id}/complete',
            path: {
                'id': id,
            },
            errors: {
                404: `Detected invalid status during update`,
                409: `Conflict detected during update`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Send Items to lightspeed
     * @returns any Operation triggered
     * @throws ApiError
     */
    public sendItemsToLightspeed({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/buyouts/{id}/send-items-to-lightspeed',
            path: {
                'id': id,
            },
            errors: {
                404: `Detected invalid status`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Upload an image
     * @returns ImageDTO uploaded image metadata
     * @throws ApiError
     */
    public uploadImage({
formData,
}: {
formData?: {
file?: Blob;
},
}): CancelablePromise<ImageDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/images',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a list of items
     * @returns ItemDTO A list of items
     * @throws ApiError
     */
    public getItems({
offset,
limit,
filterKey,
filterValue,
sortKey,
sortOrder,
}: {
/**
 * The number of items to skip before starting to collect the result set
 */
offset?: number,
/**
 * The numbers of items to return
 */
limit?: number,
/**
 * name of the field to filter by
 */
filterKey?: Array<string>,
/**
 * value of the field to filter with
 */
filterValue?: Array<string>,
sortKey?: string,
sortOrder?: SortOrderDTO,
}): CancelablePromise<Array<ItemDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items',
            query: {
                'offset': offset,
                'limit': limit,
                'filter-key': filterKey,
                'filter-value': filterValue,
                'sort-key': sortKey,
                'sort-order': sortOrder,
            },
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a item
     * @returns ItemDTO A item
     * @throws ApiError
     */
    public getItem({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<ItemDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `No item found for the provided \`Id\``,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Patch a item
     * Body schema should follow format RFC 6902 (application/json-patch+json)
     * @returns any Success patching a item
     * @throws ApiError
     */
    public updateItem({
id,
requestBody,
}: {
/**
 * The unique identifier
 */
id: Id,
requestBody?: any,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/items/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `No item found for the provided \`Id\``,
                409: `Conflict detected during update`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Delete a item
     * @returns any Success deleting a item
     * @throws ApiError
     */
    public deleteItem({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/items/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `No item found for the provided \`Id\``,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch an item tags
     * @returns TagDTO A list of tags associated to a item
     * @throws ApiError
     */
    public getItemTags({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<Array<TagDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/{id}/tags',
            path: {
                'id': id,
            },
            errors: {
                404: `No item found for the provided \`Id\``,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Patch item tags
     * @returns ItemDTO Success patching the tags
     * @throws ApiError
     */
    public updateItemTags({
id,
requestBody,
}: {
/**
 * The unique identifier
 */
id: Id,
/**
 * Patch item tags payload
 */
requestBody?: PatchItemTagsDTO,
}): CancelablePromise<ItemDTO> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/items/{id}/tags',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `No tag found for the provided \`Id\``,
                409: `Conflict detected during update`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch an item images
     * @returns ImageDTO A list of images associated to a item
     * @throws ApiError
     */
    public getItemImages({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<Array<ImageDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/items/{id}/images',
            path: {
                'id': id,
            },
            errors: {
                404: `No item found for the provided \`Id\``,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Create a template
     * @returns TemplateDTO The newly created template
     * @throws ApiError
     */
    public addTemplate({
requestBody,
}: {
/**
 * Template payload
 */
requestBody?: TemplateDTO,
}): CancelablePromise<TemplateDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/templates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a list of templates
     * @returns TemplateDTO A list of templates
     * @throws ApiError
     */
    public getTemplates({
offset,
limit,
filterKey,
filterValue,
}: {
/**
 * The number of items to skip before starting to collect the result set
 */
offset?: number,
/**
 * The numbers of items to return
 */
limit?: number,
/**
 * name of the field to filter by
 */
filterKey?: Array<string>,
/**
 * value of the field to filter with
 */
filterValue?: Array<string>,
}): CancelablePromise<Array<TemplateDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/templates',
            query: {
                'offset': offset,
                'limit': limit,
                'filter-key': filterKey,
                'filter-value': filterValue,
            },
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a template
     * @returns TemplateDTO A template
     * @throws ApiError
     */
    public getTemplate({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<TemplateDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `No template found for the provided \`Id\``,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Delete a template
     * @returns any Success deleting a template
     * @throws ApiError
     */
    public deleteTemplate({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/templates/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `No template found for the provided \`Id\``,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Create a NotificationType
     * @returns NotificationTypeDTO The newly created template
     * @throws ApiError
     */
    public addNotificationType({
requestBody,
}: {
/**
 * Notification Type payload
 */
requestBody?: NotificationTypeDTO,
}): CancelablePromise<NotificationTypeDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/notificationTypes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `No template found for the provided \`templateId\``,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Patch a notification type
     * Body schema should follow format RFC 6902 (application/json-patch+json)
     * @returns any Success patching a user
     * @throws ApiError
     */
    public updateNotificationType({
id,
requestBody,
}: {
/**
 * The unique identifier
 */
id: Id,
requestBody?: any,
}): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/notificationTypes/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `No NotificationType found for the provided \`Id\``,
                409: `Conflict detected during update`,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a list of notifications
     * @returns NotificationListItemDTO A list of notifications
     * @throws ApiError
     */
    public getNotifications({
offset,
limit,
filterKey,
filterValue,
sortKey,
sortOrder,
}: {
/**
 * The number of items to skip before starting to collect the result set
 */
offset?: number,
/**
 * The numbers of items to return
 */
limit?: number,
/**
 * name of the field to filter by
 */
filterKey?: Array<string>,
/**
 * value of the field to filter with
 */
filterValue?: Array<string>,
sortKey?: string,
sortOrder?: SortOrderDTO,
}): CancelablePromise<Array<NotificationListItemDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/notifications',
            query: {
                'offset': offset,
                'limit': limit,
                'filter-key': filterKey,
                'filter-value': filterValue,
                'sort-key': sortKey,
                'sort-order': sortOrder,
            },
            errors: {
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a notification
     * @returns NotificationDTO A notification
     * @throws ApiError
     */
    public getNotification({
id,
}: {
/**
 * The unique identifier
 */
id: Id,
}): CancelablePromise<NotificationDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/notifications/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `No notification found for the provided \`Id\``,
                500: `Unexpected error`,
            },
        });
    }

    /**
     * Fetch a list of rejections
     * @returns any A list of rejections
     * @throws ApiError
     */
    public getRejectionOptions(): CancelablePromise<{
data: Array<RejectionTypeDTO>;
pagination: PaginationDTO;
}> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/rejectionOptions',
            errors: {
                500: `Unexpected error`,
            },
        });
    }

}
