import { useAuth0 } from '@auth0/auth0-react'

export const useLogout = (): (() => void) => {
  const { logout } = useAuth0()

  const redirect = process.env.REACT_APP_AUTH0_CALLBACK_URL

  const newLogout =
    redirect !== undefined
      ? () => {
        logout({ logoutParams: { returnTo: redirect ?? '' } })
      }
      : () => {
        logout()
      }

  return newLogout
}
