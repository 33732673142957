import client from '../api/configs'
import { mapToChildList } from '../children/mapper'
import { Child } from '../children/types'
import { PaginatedData } from '../common/types'
import { ChildDTO, UserDTO } from '../generated'
import { mapToUser, mapToUserList } from './mapper'
import { User } from './types'

export const GetAll = async (
  offset?: number,
  limit?: number,
  filterKey?: Array<string>,
  filterValue?: Array<string>,
): Promise<PaginatedData<User>> => {
  const res = await client.getUsers({ offset, limit, filterKey, filterValue })
  return {
    data: mapToUserList(res.data ?? []),
    pagination: {
      totalRecords: res.pagination.total_records,
      page: res.pagination.page,
      perPage: res.pagination.per_page,
    },
  }
}

export const GetById = async (id: string): Promise<User> => {
  const data = await client.getUser({ id })
  return mapToUser(data)
}

export const GetChildren = async (
  id: string,
  offset?: number,
  limit?: number,
  filterKey?: Array<string>,
  filterValue?: Array<string>,
): Promise<Child[]> => {
  const data = await client.getUserChildren({ id, offset, limit, filterKey, filterValue })
  return mapToChildList(data)
}

export const SearchUsers = async (
  offset: number,
  limit: number,
  term: string,
  filterKey?: Array<string>,
  filterValue?: Array<string>,
): Promise<User[]> => {
  const data = await client.searchUsers({
    offset,
    limit,
    term,
    filterKey,
    filterValue,
  })
  return mapToUserList(data)
}

export const Create = async (request: UserDTO): Promise<void> => {
  await client.addUser({ requestBody: request })
}

export const CreateChild = async (id: string, request: ChildDTO): Promise<void> => {
  await client.addUserChild({ id, requestBody: request })
}

export const Patch = async (id: string, request: object): Promise<void> => {
  await client.updateUser({ id, requestBody: request })
}

export const Delete = async (id: string): Promise<void> => {
  await client.deleteUser({ id })
}
