import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { formDataSchema, FormData } from './validation/schema'
import { Popup } from '../../../../common/modal'
import {
  mapFormDataToItemDTO,
  mapToPatchItemRequest,
  maptToPatchItemTagsDTO,
} from '../../../../../services/items/mapper'
import axios from 'axios'
import { CreateItem } from '../../../../../services/buyouts'
import { useSetToken } from '../../../../../hooks/useSetToken'
import { NumericFormat } from 'react-number-format'
import { GetById, GetImages, GetTags, Patch, PatchTags } from '../../../../../services/items'
import { Item, Image as ItemImage } from '../../../../../services/items/types'
import { GetAll, SearchTags } from '../../../../../services/tags'
import { SearchTag, Tag, TagTypes } from '../../../../../services/tags/types'
import { TagDTO } from '../../../../../services/generated'
import { ActionMeta } from 'react-select/dist/declarations/src'
import { mapTagToOption } from '../../../../../services/tags/mapper'
import { useDispatch } from 'react-redux'
import { dispatchToast } from '../../../../../store/app/slice'
import { DisplayError } from '../../../../common/error'
import { Option } from '../../../../../services/common/types'
import { SButton, SButtonDanger, SLabel } from '../../../../../theme/commonComponents'
import { SASelect } from '../../../../../theme/styledSelect'

type Props = {
  buyoutId: string
  userId: string
  show: boolean
  editMode?: boolean
  itemId?: string
  onClose: () => void
  setOnLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const AddEditItemModal = ({
  buyoutId,
  itemId,
  userId,
  show,
  onClose,
  setOnLoading,
  editMode = false,
}: Props) => {
  const {
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver(formDataSchema) })
  const [item, setItem] = useState<Item>({} as Item)
  // const [tagsIds, setTagsIds] = useState<{ id: string; type: string }[]>([])
  const [tags, setTags] = useState<TagDTO[]>([])
  const [tagsLoaded, setTagsLoaded] = useState<boolean>(!editMode)
  const [patternTag, setPatternTag] = useState<Tag>()
  const dispatch = useDispatch()
  useSetToken()

  const setTagFieldValue = (type: string, value: string) => {
    switch (type) {
    case TagTypes.SIZE:
      setValue('sizeTag', value, { shouldValidate: true })
      break
    case TagTypes.BRAND:
      setValue('brandTag', value, { shouldValidate: true })
      break
    case TagTypes.CATEGORY:
      setValue('categoryTag', value, { shouldValidate: true })
      break
    case TagTypes.GENDER:
      setValue('genderTag', value, { shouldValidate: true })
      break
    case TagTypes.CONDITION:
      setValue('conditionTag', value, { shouldValidate: true })
      break
    case TagTypes.COLOR: {
      let arr = getValues('colorTags')
      if (arr === undefined) {
        arr = []
      }

      arr.push(value)

      setValue('colorTags', arr, { shouldValidate: true })
      break
    }
    case TagTypes.PATTERN:
      setValue('patternTag', value, { shouldValidate: true })
      break
    default:
      break
    }
  }

  const setItemTags = (list: TagDTO[]) => {
    const values = Object.values(TagTypes)

    values.forEach((value) => {
      const res = list.filter((e) => e.type === value).length
      if (res <= 0) {
        list.push({ id: undefined, type: value })
      }
    })

    setTags(list)
  }

  useEffect(() => {
    setValue('buyoutId', buyoutId)
    setValue('userId', userId)
    SearchTags(0, 10, 'Name', 'Print', ['Type'], ['Pattern']).then((data) => {
      setPatternTag(data[0])
    })
  }, [])

  useEffect(() => {
    if (itemId !== undefined) {
      try {
        GetById(itemId)
          .then((data) => {
            setValue('buyoutId', buyoutId)
            setValue('userId', userId)
            // setValue('cashOffer', data.cashOffer.toString())
            setValue('storeCreditOffer', data.storeCreditOffer.toString())
            setItem(data)
          })
          .catch((error) => {
            console.error(error)
            dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
          })

        GetImages(itemId)
          .then((data) => {
            setItem({ ...item, images: item !== undefined ? data : ([] as ItemImage[]) })
            setValue('images', data)
          })
          .catch((error) => {
            console.error(error)
            dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
          })

        GetTags(itemId)
          .then((data) => {
            setItemTags(data)
            data.forEach((elem) => setTagFieldValue(elem.type ?? '', elem.id ?? ''))
            // setTagsIds(data.map((x) => ({ id: x.id ?? '', type: x.type ?? '' })))
            setTagsLoaded(true)
          })
          .catch((error) => {
            console.error(error)
            dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
          })
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log('error message: ', error.message)
          dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
        } else {
          console.log('unexpected error:', error)
          dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
        }
      }
    }
  }, [])

  const handleClose = () => {
    onClose()
    setOnLoading(false)
  }

  /*TODO: DO NOT REMOVE, TO BE IMPLEMENTED WHEN BE IS READY*/
  /* const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files as FileList
    if (!isValid(files)) {
      setError('images', { type: 'manual', message: 'Picture is required' })
      return
    }
    let promises = new Array<Promise<ImageDTO>>()
    Array.from(files).forEach((element) => {
      if (!isValidFileType(element.name.toLowerCase(), 'image')) {
        setError('images', { type: 'manual', message: 'Not a valid image type' })
        return
      }

      setError('images', { type: 'manual', message: '' })
      try {
        promises.push(UploadImage(element))
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log('error message: ', error.message)
          return error.message
        } else {
          console.log('unexpected error:', error)
          return 'An unexpected error occured'
        }
      }
    })

    Promise.all(promises).then((values) => {
      let images = new Array<Image>()
      values.forEach((element) => {
        images.push({
          imageBlobId: element.image_blob_id,
          imageUrl: element.image_url,
        })
      })
      console.log(images)
      setValue('images', images ?? null)
    })
  } */

  const loadDefaultTagOptions = (tagType: string): Promise<Option[]> => {
    return new Promise<Option[]>((resolve) => {
      GetAll(0, 10, ['Type'], [tagType], undefined, undefined).then((data) => {
        resolve(mapTagToOption(data.data))
      })
    })
  }

  const loadTagOptions = (inputValue: string, tagType: string) => {
    if (inputValue !== '') {
      const obj = JSON.parse(inputValue) as SearchTag
      const isEmpty = obj.searchValue === '' || obj.searchValue === undefined
      //    setTagFieldValue(obj.tagType, obj.searchValue, isEmpty)
      if (!isEmpty) {
        return new Promise<Option[]>((resolve) => {
          SearchTags(0, 10, 'Name', obj.searchValue, ['Type'], [obj.tagType ?? '']).then((data) => {
            resolve(mapTagToOption(data))
          })
        })
      }
      return loadDefaultTagOptions(tagType)
    }
    return loadDefaultTagOptions(tagType)
  }

  const setTagOption = (id: string): Option | undefined => {
    const value = tags.filter((option) => option.id === id)
    if (value.length > 0) {
      return { label: value[0].name, value: value[0].id } as Option
    }
    return undefined
  }

  const setMultiTagOption = (ids: string[]): Option[] | undefined => {
    if (ids !== undefined) {
      let arr: Option[] = []
      ids.forEach((x) => {
        const value = setTagOption(x)
        if (value != undefined) {
          arr.push(value)
        }
      })
      return arr
    }

    return undefined
  }

  const onChange = (option: Option | null, actionMeta: ActionMeta<Option>, type: string) => {
    setTagFieldValue(type, option?.value ?? '')
  }

  const onMultiChange = (
    options: readonly Option[],
    actionMeta: ActionMeta<Option>,
    type: string,
  ) => {
    setValue('colorTags', []) // clear form values because here we received always the updated options selected
    options.forEach((x) => setTagFieldValue(type, x.value))
  }

  const create = (data: FormData) => {
    try {
      const request = mapFormDataToItemDTO(data)
      CreateItem(buyoutId, request)
        .then(() => {
          dispatch(dispatchToast({ title: 'Item created!', type: 'success' }))
          reset()
          handleClose()
          setOnLoading(true)
        })
        .catch((error) => {
          console.error(error)
          dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
        })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
        dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
      } else {
        console.log('unexpected error:', error)
        dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
      }
    }
  }

  const patch = (data: FormData) => {
    try {
      const request = mapToPatchItemRequest(item ?? ({} as Item), data)
      Patch(itemId ?? '', request).then(() => {
        const tagsRequest = maptToPatchItemTagsDTO(tags, data)
        PatchTags(itemId ?? '', tagsRequest)
          .then(() => {
            dispatch(dispatchToast({ title: 'Item updated!', type: 'success' }))
            reset()
            handleClose()
            setOnLoading(true)
          })
          .catch((error) => {
            console.error(error)
            dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
          })
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
        dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
      } else {
        console.log('unexpected error:', error)
        dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
      }
    }
  }

  const onSubmit = (data: FormData) => {
    if (editMode) {
      patch(data)
    } else {
      create(data)
    }
  }

  const handleCheckboxOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValue('patternTag', patternTag?.id ?? '')
    } else {
      console.log(patternTag?.id)
      setValue('patternTag', '')
    }
  }

  const renderForm = () => {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/*TODO: DO NOT REMOVE, TO BE IMPLEMENTED WHEN BE IS READY*/}
          {/* <div className='row mb-3'>
              <div className='col-md-12'>
                <label htmlFor='picture' className='col-form-label'>
                  Picture
                </label>
                <input
                  type='file'
                  multiple
                  className='form-control form-control-sm'
                  id='picture'
                  {...register('picture')}
                  onChange={(e) => uploadImage(e)}
                />
                {errors?.images && <p>{errors.images.message}</p>}
                {errors?.images?.[0]?.imageBlobId && (
                  <p>{errors.images?.[0]?.imageBlobId.message}</p>
                )}
                {errors?.images?.[0]?.imageUrl && <p>{errors.images?.[0]?.imageUrl.message}</p>}
              </div>
            </div> */}
          {/*TODO: DO NOT REMOVE, TO BE IMPLEMENTED*/}
          {/*<div className='row mb-3'>
               <div className='col-md-12'>
                 
                <label htmlFor='cashOffer' className='col-form-label'>
                  Cash offer
                </label>
                <Controller
                  render={({ field: { onChange, name, value } }) => (
                    <NumericFormat
                      className='form-control form-control-sm'
                      id='cashOffer'
                      name={name}
                      thousandsGroupStyle='lakh'
                      thousandSeparator=','
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  name='cashOffer'
                  control={control}
                />
                <DisplayError field={errors?.cashOffer} />
              </div> 
            </div>*/}
          <div className='row mb-3'>
            <div className='col-12 col-sm-6 col-lg-6'>
              <SLabel htmlFor='storeCreditOffer'>Store credit offfer</SLabel>
              <Controller
                render={({ field: { onChange, name, value } }) => (
                  <NumericFormat
                    className='form-control form-control-sm'
                    id='storeCreditOffer'
                    name={name}
                    thousandsGroupStyle='lakh'
                    thousandSeparator=','
                    value={value}
                    onChange={onChange}
                  />
                )}
                name='storeCreditOffer'
                control={control}
              />
              <DisplayError field={errors?.storeCreditOffer} />
            </div>
            <div className='col-12 col-sm-6 col-lg-6'>
              <SLabel htmlFor='sizes'>Size</SLabel>
              {tagsLoaded && (
                <SASelect
                  defaultOptions={true}
                  defaultValue={editMode ? setTagOption(getValues('sizeTag')) : undefined}
                  loadOptions={(inputValue: string) => loadTagOptions(inputValue, TagTypes.SIZE)}
                  onChange={(option: Option | null, actionMeta: ActionMeta<Option>) =>
                    onChange(option, actionMeta, TagTypes.SIZE)
                  }
                  onInputChange={(newValue: string) =>
                    JSON.stringify({ searchValue: newValue, tagType: TagTypes.SIZE })
                  }
                />
              )}
              <DisplayError field={errors?.sizeTag} />
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-12 col-sm-6 col-lg-6'>
              <SLabel htmlFor='brands'>Brand</SLabel>
              {tagsLoaded && (
                <SASelect
                  defaultOptions={true}
                  defaultValue={editMode ? setTagOption(getValues('brandTag')) : undefined}
                  loadOptions={(inputValue: string) => loadTagOptions(inputValue, TagTypes.BRAND)}
                  onChange={(option: Option | null, actionMeta: ActionMeta<Option>) =>
                    onChange(option, actionMeta, TagTypes.BRAND)
                  }
                  onInputChange={(newValue: string) =>
                    JSON.stringify({ searchValue: newValue, tagType: TagTypes.BRAND })
                  }
                />
              )}
              <DisplayError field={errors?.brandTag} />
            </div>
            <div className='col-12 col-sm-6 col-lg-6'>
              <SLabel htmlFor='categories'>Category</SLabel>
              {tagsLoaded && (
                <SASelect
                  defaultOptions={true}
                  defaultValue={editMode ? setTagOption(getValues('categoryTag')) : undefined}
                  loadOptions={(inputValue: string) =>
                    loadTagOptions(inputValue, TagTypes.CATEGORY)
                  }
                  onChange={(option: Option | null, actionMeta: ActionMeta<Option>) =>
                    onChange(option, actionMeta, TagTypes.CATEGORY)
                  }
                  onInputChange={(newValue: string) =>
                    JSON.stringify({ searchValue: newValue, tagType: TagTypes.CATEGORY })
                  }
                />
              )}
              <DisplayError field={errors?.categoryTag} />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-12 col-sm-6 col-lg-6'>
              <SLabel htmlFor='gender'>Gender</SLabel>
              {tagsLoaded && (
                <SASelect
                  defaultOptions={true}
                  defaultValue={editMode ? setTagOption(getValues('genderTag')) : undefined}
                  loadOptions={(inputValue: string) => loadTagOptions(inputValue, TagTypes.GENDER)}
                  onChange={(option: Option | null, actionMeta: ActionMeta<Option>) =>
                    onChange(option, actionMeta, TagTypes.GENDER)
                  }
                  onInputChange={(newValue: string) =>
                    JSON.stringify({ searchValue: newValue, tagType: TagTypes.GENDER })
                  }
                />
              )}
              <DisplayError field={errors?.genderTag} />
            </div>
            <div className='col-12 col-sm-6 col-lg-6'>
              <SLabel htmlFor='condition'>Condition</SLabel>
              {tagsLoaded && (
                <SASelect
                  defaultOptions={true}
                  defaultValue={editMode ? setTagOption(getValues('conditionTag')) : undefined}
                  loadOptions={(inputValue: string) =>
                    loadTagOptions(inputValue, TagTypes.CONDITION)
                  }
                  onChange={(option: Option | null, actionMeta: ActionMeta<Option>) =>
                    onChange(option, actionMeta, TagTypes.CONDITION)
                  }
                  onInputChange={(newValue: string) =>
                    JSON.stringify({ searchValue: newValue, tagType: TagTypes.CONDITION })
                  }
                />
              )}
              <DisplayError field={errors?.conditionTag} />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-12'>
              <SLabel htmlFor='color'>Colors</SLabel>
              {tagsLoaded && (
                <SASelect
                  defaultOptions={true}
                  defaultValue={editMode ? setMultiTagOption(getValues('colorTags')) : undefined}
                  isMulti={true}
                  loadOptions={(inputValue: string) => loadTagOptions(inputValue, TagTypes.COLOR)}
                  onChange={(option: readonly Option[], actionMeta: ActionMeta<Option>) =>
                    onMultiChange(option, actionMeta, TagTypes.COLOR)
                  }
                  onInputChange={(newValue: string) =>
                    JSON.stringify({ searchValue: newValue, tagType: TagTypes.COLOR })
                  }
                />
              )}
              <DisplayError field={errors?.colorTags} />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              {tagsLoaded && (
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value={patternTag?.id}
                    id='patternCheckbox'
                    defaultChecked={
                      editMode
                        ? getValues('patternTag') !== undefined && getValues('patternTag') !== ''
                        : false
                    }
                    onChange={handleCheckboxOnChange}
                  />
                  <SLabel htmlFor='patternCheckbox'>Pattern</SLabel>
                </div>
              )}
              <DisplayError field={errors?.patternTag} />
            </div>
          </div>
          <div className='d-flex justify-content-end mt-5'>
            <SButtonDanger className='me-3' onClick={handleClose}>
              Close
            </SButtonDanger>
            <SButton type='submit'>Save Item</SButton>
          </div>
        </form>
      </>
    )
  }

  return (
    <>
      <Popup
        backdrop='static'
        show={show}
        title={editMode ? 'Edit item' : 'Add a new item'}
        handleClose={handleClose}
        content={renderForm()}
      />
    </>
  )
}
