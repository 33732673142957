import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState, Toast, User } from './types';

const INITIAL_STATE: AppState = {
  user: {
    id: '',
    isAdmin: false,
  },
  toast: {
    title: '',
    type: 'default',
  },
}

const slice = createSlice({
  name: 'app',
  initialState: INITIAL_STATE,
  reducers: {
    updateUser(state: AppState, action: PayloadAction<User>) {
      state.user = action.payload
    },
    dispatchToast(state: AppState, action: PayloadAction<Toast>) {
      state.toast = action.payload
    }
  }
})

export const {
  updateUser,
  dispatchToast,
} = slice.actions

export default slice.reducer