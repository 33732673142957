import { DriversLicenseRegex } from './regex'

export interface ValidationMatch {
  state: string
  description: string
}

export const isValid = (value: string, state: string): boolean => {
  console.log(state)
  const results: ValidationMatch[] = []
  const format = DriversLicenseRegex[state]

  if (!format) {
    throw new Error(`Could not find state "${state}"!`)
  }

  format.forEach((item) => {
    const { rule, description } = item
    const pattern = new RegExp(rule, 'i')

    if (pattern.test(value)) {
      results.push({
        description,
        state,
      })
    }
  })

  return results.length > 0
}
