import styled from 'styled-components'

export const StyleWrap = styled.div`
  .modal-confirm {
    color: #636363;
    width: 325px;
    font-size: 14px;
  }
  .modal-confirm .modal-content {
    padding: 20px;
    border-radius: 5px;
    border: none;
  }
  .modal-confirm .modal-header {
    border-bottom: none;
    position: relative;
  }
  .modal-confirm h4 {
    text-align: center;
    font-size: 26px;
    margin: 30px 0 -15px;
  }
  .modal-confirm .form-control,
  .modal-confirm .btn {
    min-height: 40px;
    border-radius: 3px;
  }
  .modal-confirm .close {
    position: absolute;
    top: -5px;
    right: -5px;
  }
  .modal-confirm .modal-footer {
    border: none;
    text-align: center;
    border-radius: 5px;
    font-size: 13px;
  }
  .modal-confirm .icon-box {
    color: #fff;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -75px;
    border-radius: 50%;
    z-index: 9;
    padding: 15px;
    text-align: center;
  }
  .modal-confirm .icon-box i {
    font-size: 58px;
    position: relative;
    top: 3px;
  }
  .modal-confirm.modal-dialog {
    margin-top: 15%;
  }
  .modal-confirm .btn {
    color: #fff;
    border-radius: 4px;
    background: #82ce34;
    text-decoration: none;
    transition: all 0.4s;
    line-height: normal;
    border: none;
  }
  .modal-confirm .btn:hover,
  .modal-confirm .btn:focus {
    background: #6fb32b;
    outline: none;
  }
  .trigger-btn {
    display: inline-block;
    margin: 100px auto;
  }
`
