import { Buyout } from '../../../../../../services/buyouts/types'
import 'react-datepicker/dist/react-datepicker.css'
import { buyoutStatus } from '../../../../../../services/buyouts/mapper'
import {
  Icon,
  SDatePicker,
  SHR,
  SIconButtonDark,
  SLabel,
  STitle,
} from '../../../../../../theme/commonComponents'

type Props = {
    buyout: Buyout
    editMode?: boolean
    showCreateEditModal?: React.Dispatch<React.SetStateAction<boolean>>
}

export const BuyoutHeader = ({ buyout, showCreateEditModal, editMode = false }: Props) => {
  return (
    <>
      <div className='row mb-3'>
        <div className='col-12'>
          <div className='d-flex flex-row justify-content-between align-items-center'>
            <STitle>Buyout details</STitle>
            {editMode && buyout.status !== buyoutStatus.COMPLETED ? (
              <SIconButtonDark
                title='Edit'
                id='openEditModal'
                className='btn p-0 m-0'
                onClick={() => showCreateEditModal?.(true)}
              >
                <Icon className='bi-pencil-square'></Icon>
              </SIconButtonDark>
            ) : (
              ''
            )}
          </div>
          <SHR />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-sm-6 col-12 col-lg-1'>
          <SLabel htmlFor='pwe'>PWE</SLabel>
          <input
            type='text'
            className='form-control form-control-sm'
            id='pwe'
            value={buyout?.pwe ?? ''}
            disabled
          />
        </div>
        <div className='col-sm-6 col-12 col-lg-1'>
          <SLabel htmlFor='receivedQuantity'>Received Qty</SLabel>
          <input
            className='form-control form-control-sm'
            type='number'
            id='receivedQuantity'
            value={buyout?.receivedQuantity ?? 0}
            disabled
          />
        </div>
        <div className='col-sm-6 col-12 col-lg-2'>
          <SLabel htmlFor='status'>Sub total</SLabel>
          <input
            className='form-control form-control-sm'
            type='text'
            id='subtotal'
            value={'$'+buyout?.subtotal ?? ''}
            disabled
          />
        </div>
        <div className='col-sm-6 col-12 col-lg-4'>
          <SLabel htmlFor='status'>Status</SLabel>
          <input
            className='form-control form-control-sm'
            type='text'
            id='status'
            value={buyout?.status ?? ''}
            disabled
          />
        </div>
        <div className='col-sm-6 col-12 col-lg-4'>
          <SLabel htmlFor='user'>User</SLabel>
          <input
            className='form-control form-control-sm'
            type='text'
            id='status'
            value={buyout?.user?.firstName + ' ' + buyout?.user?.lastName ?? ''}
            disabled
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-sm-12 col-12 col-lg-4'>
          <SLabel htmlFor='email'>Email</SLabel>
          <input
            className='form-control form-control-sm'
            type='text'
            id='status'
            value={buyout?.user?.email ?? ''}
            disabled
          />
        </div>
        <div className='col-sm-6 col-12 col-lg-4'>
          <SLabel htmlFor='buyDate'>Buyout date</SLabel>
          {buyout?.buyDate != undefined ? (
            <SDatePicker
              id='buyDate'
              className='form-control form-control-sm'
              dateFormat='MM-dd-yyyy'
              selected={buyout?.buyDate}
              disabled
              onChange={() => { }}
            />
          ) : (
            <input id='buyDate' className='form-control form-control-sm' type='text' disabled />
          )}
        </div>
        <div className='col-sm-6 col-12 col-lg-4'>
          <SLabel htmlFor='receptionDate'>Reception date</SLabel>
          {buyout?.receptionDate != undefined ? (
            <SDatePicker
              id='receptionDate'
              className='form-control form-control-sm'
              dateFormat='MM-dd-yyyy'
              selected={buyout?.receptionDate}
              disabled
              onChange={() => { }}
            />
          ) : (
            <input
              id='receptionDate'
              className='form-control form-control-sm'
              type='text'
              disabled
            />
          )}
        </div>
      </div>
    </>
  )
}
