import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { formDataSchema, FormData } from './validation/schema'
import { Popup } from '../../../../common/modal'
import { User } from '../../../../../services/account/types'
import {
  mapToCreateUserRequest,
  mapToPatchUserRequest,
} from '../../../../../services/account/mapper'
import { Create, Patch } from '../../../../../services/account'
import { USStateOptions } from '../../../../../consts/usStates'
import { Option } from '../../../../../services/common/types'
import { DisplayError } from '../../../../common/error'
import { SButton, SButtonDanger, SLabel } from '../../../../../theme/commonComponents'
import SSelect from '../../../../../theme/styledSelect'
import { dispatchToast } from '../../../../../store/app/slice'
import { useDispatch } from 'react-redux'

type Props = {
  user: User
  show: boolean
  editMode?: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

export const CreateEditModal = ({ show, user, setShow, editMode = false }: Props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(formDataSchema),
  })
  const dispatch = useDispatch()
  
  useEffect(() => {
    if (user !== undefined) {
      setValue('id', user.id)
      setValue('firstName', user.firstName)
      setValue('lastName', user.lastName)
      setValue('address', user.address)
      setValue('city', user.city)
      setValue('state', user.state)
      setValue('zipCode', user.zipCode)
      setValue('phone', user.phone)
      setValue('email', user.email)
    }
  }, [])

  const handleClose = () => {
    setShow(false)
  }

  const onChange = (option: Option | null, fieldName: string) => {
    setValue(fieldName as any, option?.value ?? '', { shouldValidate: true })
  }

  const createUser = (data: FormData) => {
    const request = mapToCreateUserRequest(data)
    try {
      Create(request).then(() => {
        console.log('Success')
        dispatch(dispatchToast({ title: 'Account created!', type: 'success' }))
        reset()
        handleClose()
      })
    } catch (error) {
      dispatch(dispatchToast({ title: 'Something wrong happened!', type: 'error' }))
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
        return error.message
      } else {
        console.log('unexpected error:', error)
        return 'An unexpected error occured'
      }
    }
  }

  const patchUser = (data: FormData) => {
    const request = mapToPatchUserRequest(user, data)
    try {
      Patch(user?.id ?? '', request).then(() => {
        console.log('Success')
        dispatch(dispatchToast({ title: 'Account updated!', type: 'success' }))
        reset()
        handleClose()
      })
    } catch (error) {
      dispatch(dispatchToast({ title: 'Something wrong happened!', type: 'error' }))
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
        return error.message
      } else {
        console.log('unexpected error:', error)
        return 'An unexpected error occured'
      }
    }
  }

  const onSubmit = (data: FormData) => {
    if (editMode) {
      patchUser(data)
    } else {
      createUser(data)
    }
  }

  const renderForm = () => {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='row mb-3'>
            <div className='col-sm-6 col-12 col-lg-3'>
              <SLabel htmlFor='firstName' >
                First name
              </SLabel>
              <input
                type='text'
                className='form-control  form-control-sm'
                id='firstName'
                {...register('firstName')}
                disabled={!editMode}
              />
              <DisplayError field={errors?.firstName} />
            </div>
            <div className='col-sm-6 col-12 col-lg-3'>
              <SLabel htmlFor='lastName' >
                Last name
              </SLabel>
              <input
                type='text'
                className='form-control  form-control-sm'
                id='lastName'
                {...register('lastName')}
                disabled={!editMode}
              />
              <DisplayError field={errors?.lastName} />
            </div>
            <div className='col-sm-6 col-12 col-lg-3'>
              <SLabel htmlFor='address' >
                Address
              </SLabel>
              <input
                type='text'
                className='form-control  form-control-sm'
                id='address'
                {...register('address')}
                disabled={!editMode}
              />
              <DisplayError field={errors?.address} />
            </div>
            <div className='col-sm-6 col-12 col-lg-3'>
              <SLabel htmlFor='city'>
                City
              </SLabel>
              <input
                type='text'
                className='form-control  form-control-sm'
                id='city'
                {...register('city')}
                disabled={!editMode}
              />
              <DisplayError field={errors?.city} />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-sm-6 col-12 col-lg-3'>
              <SLabel htmlFor='state'>
                State
              </SLabel>
              <SSelect
                defaultValue={USStateOptions.filter((x) => x.value == user?.state ?? '')?.[0]}
                options={USStateOptions}
                onChange={(option: Option | null) => onChange(option, 'state')}
              />
              <DisplayError field={errors?.state} />
            </div>
            <div className='col-sm-6 col-12 col-lg-3'>
              <SLabel htmlFor='zipCode'>
                ZIP/Postal code
              </SLabel>
              <input
                type='text'
                className='form-control  form-control-sm'
                id='zipCode'
                {...register('zipCode')}
                disabled={!editMode}
              />
              <DisplayError field={errors?.zipCode} />
            </div>
            <div className='col-sm-6 col-12 col-lg-3'>
              <SLabel htmlFor='phone'>
                Phone
              </SLabel>
              <input
                type='text'
                className='form-control  form-control-sm'
                id='phone'
                {...register('phone')}
                disabled={!editMode}
              />
              <DisplayError field={errors?.phone} />
            </div>
            <div className='col-sm-6 col-12 col-lg-3'>
              <SLabel htmlFor='email'>
                Email address
              </SLabel>
              <input
                type='text'
                className='form-control  form-control-sm'
                id='email'
                {...register('email')}
                disabled
              />
              <DisplayError field={errors?.email} />
            </div>
          </div>
          <div className='d-flex justify-content-end mt-5'>
            <SButtonDanger className='me-3' onClick={handleClose}>
              Close
            </SButtonDanger>
            <SButton type='submit'>
              Save Changes
            </SButton>
          </div>
        </form>
      </>
    )
  }

  return (
    <>
      <Popup
        show={show}
        backdrop='static'
        title={editMode ? 'Edit Account' : 'New Account'}
        handleClose={handleClose}
        content={renderForm()}
      />
    </>
  )
}
