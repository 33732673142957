import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { formDataSchema, FormData } from './validation/schema'
import { Popup } from '../../../../common/modal'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import { CreateChild } from '../../../../../services/account'
import { mapFormDataToChildDTO } from '../../../../../services/children/mapper'
import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../../../../store'
import { User as UserState } from '../../../../../store/app/types'
import { DisplayError } from '../../../../common/error'
import { SButton, SButtonDanger, SDatePicker, SLabel } from '../../../../../theme/commonComponents'
import { dispatchToast } from '../../../../../store/app/slice'

type Props = {
  userId: string
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  setOnLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const AddChildModal = ({ userId, show, setShow, setOnLoading }: Props) => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined)
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver(formDataSchema) })
  const userState = useSelector<State, UserState>((state) => state.app.user)
  const dispatch = useDispatch()


  useEffect(() => {
    setValue('userId', userId)
  }, [])

  const handleClose = () => {
    setOnLoading(false)
    setShow(false)
  }

  const handleDateChange = (date: Date | null) => {
    if (date !== null) {
      setStartDate(date)
      setValue('birthday', date)
    }
  }

  const onSubmit = (data: FormData) => {
    try {
      data.userId = userState.id
      const request = mapFormDataToChildDTO(data)
      CreateChild(data.userId, request).then(() => {
        console.log('Success')
        dispatch(dispatchToast({ title: 'Child created!', type: 'success' }))
        reset()
        handleClose()
        setOnLoading(true)
      })
    } catch (error) {
      dispatch(dispatchToast({ title: 'Something wrong happened!', type: 'error' }))
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
        return error.message
      } else {
        console.log('unexpected error:', error)
        return 'An unexpected error occured'
      }
    }
  }

  const renderForm = () => {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='row mb-3'>
            <div className='col-sm-12 col-12 col-lg-12'>
              <SLabel htmlFor='firstName'>
                Name
              </SLabel>
              <input
                type='text'
                className='form-control  form-control-sm'
                id='firstName'
                {...register('firstName')}
              />
              <DisplayError field={errors.firstName} />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-sm-8 col-12 col-lg-4'>
              <SLabel htmlFor='birthday'>
                Birthday
              </SLabel>
              <SDatePicker
                className='form-control form-control-sm'
                dateFormat='MM-dd-yyyy'
                selected={startDate}
                {...register('birthday')}
                onChange={handleDateChange}
              />
              <DisplayError field={errors.birthday} />
            </div>
          </div>
          <div className='d-flex justify-content-end mt-5'>
            <SButtonDanger className='me-3' onClick={handleClose}>
              Close
            </SButtonDanger>
            <SButton type='submit'>
              Add child
            </SButton>
          </div>
        </form>
      </>
    )
  }

  return (
    <>
      <Popup
        show={show}
        backdrop='static'
        title='Add a new child'
        handleClose={handleClose}
        content={renderForm()}
      />
    </>
  )
}

