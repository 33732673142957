import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../router/routes'
import { State } from '../../../store'
import { User } from '../../../store/app/types'
import LogoutButton from '../../modules/auth/logout-button'
import logo from '../../../assets/logo.png';
import { SAltDiv, SLabel, Icon, SLI, SHR, SUL } from '../../../theme/commonComponents'

export const Sidemenu = () => {
  const [active, setActive] = useState<string>('')
  const navigate = useNavigate()
  const user = useSelector<State, User>((state) => state.app.user)

  const handleClick = (id: string, route: string) => {
    setActive(id)
    navigate(route)
  }

  return (
    <SAltDiv className='h-100 row pt-4 row-cols-1' id='sidebar'>
      <div className='col align-self-start'>
        <a className='d-flex justify-content-center' href="" onClick={() => {
          navigate(ROUTES.ROOT)
        }}>
          <img width='30%' src={logo} alt="Logo" />
        </a>
        <SHR className='my-3' />
        <SUL>
          {user.isAdmin ? (
            <>
              <SLI
                id='account_bo'
                className={`${active == 'account_bo' ? 'active-option' : ''}`}
                onClick={(e) => handleClick(e.currentTarget.id, ROUTES.ACCOUNT.ROOT)}
              >
                <div className='d-flex align-items-center'>
                  <Icon className='bi-person-circle' />
                  <span className='mx-2'>Account</span>
                </div>
              </SLI>
              {/* <SLI id='users'>
                <div className='d-flex align-items-center'>
                  <Icon className='bi-people-fill' />
                  <span className='mx-2'>Users</span>
                </div>
              </SLI> */}
              <SLI
                id='buyouts_bo'
                className={`${active == 'buyouts_bo' ? 'active-option' : ''}`}
                onClick={(e) => handleClick(e.currentTarget.id, ROUTES.BUYOUTS.LIST)}
              >
                <div className='d-flex align-items-center'>
                  <Icon className='bi-bag' />
                  <span className='mx-2'>Periwinkle Buyouts</span>
                </div>
              </SLI>
              <SLI
                id='tags_bo'
                className={`${active == 'tags_bo' ? 'active-option' : ''}`}
                onClick={(e) => handleClick(e.currentTarget.id, ROUTES.TAGS.LIST)}
              >
                <div className='d-flex align-items-center'>
                  <Icon className='bi bi-tag' />
                  <span className='mx-2'>Tags</span>
                </div>
              </SLI>
            </>
          ) : (
            <>
              <SLI
                id='account_fo'
                className={`${active == 'account_fo' ? 'active-option' : ''}`}
                onClick={(e) => handleClick(e.currentTarget.id, ROUTES.ACCOUNT.ROOT)}
              >
                <div className='d-flex align-items-center'>
                  <Icon className='bi-person-circle' />
                  <span className='mx-2'>Account</span>
                </div>
              </SLI>
              <SLI
                id='buyouts_fo'
                className={`${active == 'buyouts_fo' ? 'active-option' : ''}`}
                onClick={(e) => handleClick(e.currentTarget.id, ROUTES.BUYOUTS.LIST)}
              >
                <div className='d-flex align-items-center'>
                  <Icon className='bi-bag' />
                  <span className='mx-2'>My Buyouts</span>
                </div>
              </SLI>
            </>
          )}
        </SUL>
      </div>
      <div className='col align-self-end'>
        <SHR className='my-3'  />
        <div className='d-flex justify-content-between align-items-center mb-4'>
          <img
            referrerPolicy='no-referrer'
            src={user?.picture}
            alt=''
            width='48'
            height='48'
            className='rounded-circle me-2'
          />
          <SLabel> {user?.name}</SLabel>
          <LogoutButton />
        </div>
      </div>
    </SAltDiv>
  )
}
