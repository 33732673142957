export const TagTypes = {
  SIZE: 'Size',
  BRAND: 'Brand',
  CATEGORY: 'Category',
  GENDER: 'Gender',
  COLOR: 'Color',
  CONDITION: 'Condition',
  PATTERN: 'Pattern',
}
export interface Tag {
  id: string
  name: string
  type: string
}

export type SearchTag = {
  searchValue: string
  tagType: string
}

export type SearchCategory = {
  searchValue: string
  tagType?: string
  parentId?: string
  fieldName?: string
}
