import * as yup from 'yup'

export interface FormData {
  buyoutId: string
  rejectionId: string
  rejectionText?: string
}

export const formDataSchema = (rejectionOption: string) => yup
  .object({
    buyoutId: yup.string().required(),
    rejectionId: yup.string()
      .test('valid', 'A valid reason must be selected',
        (value) => value != '0')
      .required()
      .label('Rejection option'),
    rejectionText: yup.string()
      .when(rejectionOption, {
        is: 'Other',
        then: () => yup.string().required().label('Rejection text')
      }),
  })
  .required()