import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { formDataSchema, FormData } from './validation/schema'
import { Create } from '../../../../services/account'
import { mapToRegisterUserRequest } from '../../../../services/account/mapper'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Popup } from '../../../common/modal'
import { SuccessRegistrationModal } from '../modals/successRegistration'
import { SLabel, SButton, SHR, SDatePicker, SButtonDanger } from '../../../../theme/commonComponents'
import { TermsAndConditionsModal } from '../modals/termsAndConditions'
import { DisplayError } from '../../../common/error'
import { USStateOptions } from '../../../../consts/usStates'
import { Option } from '../../../../services/common/types'
import { useLogout } from '../../../../hooks/useLogout'
import SSelect from '../../../../theme/styledSelect'

const FIELDS = {
  dateOfBirth: 'dateOfBirth',
  expirationDate: 'expirationDate',
}

type Props = {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

export const Registration = ({ setShow, show }: Props) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    trigger,
    formState: { errors },
  } = useForm<FormData>({ mode: 'onChange', resolver: yupResolver(formDataSchema) })
  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>(undefined)
  const [expirationDate, setExpirationDate] = useState<Date | undefined>(undefined)
  const [showSuccessModal, SetShowSuccessModal] = useState<boolean>(false)
  const [showConditionsModal, SetShowConditionsModal] = useState<boolean>(false)
  const { user } = useAuth0()

  useEffect(() => {
    if (user !== undefined) {
      setValue('externalId', user.sub ?? '')
      setValue('firstName', user.given_name ?? '')
      setValue('lastName', user.family_name ?? '')
      setValue('address', user.address ?? '')
      setValue('phone', user.phone_number ?? '')
      setValue('email', user.email ?? '')
      setValue('dateOfBirth', user.dateOfBirth ?? '')
    }
  }, [])

  const handleDateChange = (
    date: Date | null,
    event: React.SyntheticEvent<any, Event> | undefined,
    field: string,
  ) => {
    if (date !== null) {
      if (field === FIELDS.dateOfBirth) {
        setDateOfBirth(date)
        setValue('dateOfBirth', date.toDateString())
        setError('dateOfBirth', { type: 'manual', message: '' })
      } else {
        setExpirationDate(date)
        setValue('expirationDate', date.toDateString())
        setError('expirationDate', { type: 'manual', message: '' })
      }
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  const onChange = (option: Option | null, fieldName: string) => {
    setValue(fieldName as any, option?.value ?? '', { shouldValidate: true })
  }

  const buildTermsAndConditionsModal = () => {
    return (
      <>
        <TermsAndConditionsModal show={showConditionsModal} setShow={SetShowConditionsModal} />
      </>
    )
  }

  const buildSuccessRegistrationModal = () => {
    return (
      <>
        <SuccessRegistrationModal show={showSuccessModal} />
      </>
    )
  }

  const handleCheckboxOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      setError('acceptTermsAndConditions', {
        type: 'manual',
        message: 'Terms & Conditions must be accecpted',
      })
      setValue('acceptTermsAndConditions', event.target.checked)
    } else {
      clearErrors('acceptTermsAndConditions')
      setValue('acceptTermsAndConditions', event.target.checked)
    }
  }


  const onIssuerChange = (option: Option | null, fieldName: string) => {
    setValue(fieldName as any, option?.value ?? '', { shouldValidate: true })
    trigger('driverLicenseNumber')
  }

  // const handleDriverLicenseNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.value === '') {
  //     setError('driverLicenseNumber', {
  //       type: 'manual',
  //       message: "Driver's licence number is required",
  //     })
  //   } else if () {

  //   } else {
  //     setError('driverLicenseNumber', {
  //       type: 'manual',
  //       message: '',
  //     })
  //     setValue('driverLicenseNumber', event.target.value)
  //   }
  // }

  const onSubmit = (data: FormData) => {
    const request = mapToRegisterUserRequest(data)
    try {
      Create(request).then(() => {
        console.log('Success')
        reset()
        SetShowSuccessModal(true)
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
        return error.message
      } else {
        console.log('unexpected error:', error)
        return 'An unexpected error occured'
      }
    }
  }

  const renderForm = () => {
    const logout = useLogout()

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='row mb-3'>
          <div className='col-sm-6 col-12 col-lg-6'>
            <SLabel htmlFor='firstName'>First Name</SLabel>
            <input
              type='text'
              id='firstName'
              className='form-control form-control-sm'
              {...register('firstName')}
            />
            <DisplayError field={errors?.firstName} />
          </div>

          <div className='col-sm-6 col-12 col-lg-6'>
            <SLabel htmlFor='lastName'>Last Name</SLabel>
            <input
              type='text'
              id='lastName'
              className='form-control form-control-sm'
              {...register('lastName')}
            />
            <DisplayError field={errors?.lastName} />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-sm-4 col-12 col-lg-4'>
            <SLabel htmlFor='dateOfBirth'>Birth date</SLabel>
            <SDatePicker
              className='form-control form-control-sm'
              dateFormat='MM-dd-yyyy'
              selected={dateOfBirth}
              {...register('dateOfBirth')}
              onChange={(date: Date | null, event: React.SyntheticEvent<any, Event> | undefined) =>
                handleDateChange(date, event, FIELDS.dateOfBirth)
              }
            />
            <DisplayError field={errors?.dateOfBirth} />
          </div>
          <div className='col-sm-4 col-12 col-lg-4'>
            <SLabel htmlFor='email'>Email</SLabel>
            <input
              type='text'
              id='email'
              disabled
              className='form-control form-control-sm'
              {...register('email')}
            />
            <DisplayError field={errors?.email} />
          </div>
          <div className='col-sm-4 col-12 col-lg-4'>
            <SLabel htmlFor='phone'>Phone Number</SLabel>
            <input
              type='text'
              id='phone'
              className='form-control form-control-sm'
              {...register('phone')}
            />
            <DisplayError field={errors?.phone} />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col'>
            <SLabel htmlFor='address'>Address</SLabel>
            <input
              type='text'
              id='address'
              className='form-control form-control-sm'
              {...register('address')}
            />
            <DisplayError field={errors?.address} />
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-sm-4 col-12 col-lg-4'>
            <SLabel htmlFor='city'>City</SLabel>
            <input
              type='text'
              id='city'
              className='form-control form-control-sm'
              {...register('city')}
            />
            <DisplayError field={errors?.city} />
          </div>
          <div className='col-sm-4 col-12 col-lg-4'>
            <SLabel htmlFor='state'>State</SLabel>
            <SSelect
              options={USStateOptions}
              onChange={(option: Option | null) => onChange(option, 'state')}
            />
            <DisplayError field={errors?.state} />
          </div>
          <div className='col-sm-4 col-12 col-lg-4'>
            <SLabel htmlFor='zipCode'>ZIP/Postal code</SLabel>
            <input
              type='text'
              id='zipCode'
              className='form-control form-control-sm'
              {...register('zipCode')}
            />
            <DisplayError field={errors?.zipCode} />
          </div>
        </div>
        <SHR />
        <div className='row mb-3 mt-3'>
          <div className='col-sm-4 col-12 col-lg-4'>
            <SLabel htmlFor='issuer'>Issuer (State)</SLabel>
            <SSelect
              options={USStateOptions}
              onChange={(option: Option | null) => onIssuerChange(option, 'issuer')}
            />
            <DisplayError field={errors?.issuer} />
          </div>
          <div className='col-sm-4 col-12 col-lg-4'>
            <SLabel htmlFor='driverLicenceNumber'>Driver license number</SLabel>
            <input
              type='text'
              id='driverLicenseNumber'
              className='form-control form-control-sm'
              {...register('driverLicenseNumber')}
            />
            <DisplayError field={errors?.driverLicenseNumber} />

          </div>
          <div className='col-sm-4 col-12 col-lg-4'>
            <SLabel htmlFor='expirationDate'>Expiration date</SLabel>
            <SDatePicker
              className='form-control form-control-sm'
              dateFormat='MM-dd-yyyy'
              selected={expirationDate}
              {...register('expirationDate')}
              onChange={(date: Date | null, event: React.SyntheticEvent<any, Event> | undefined) =>
                handleDateChange(date, event, FIELDS.expirationDate)
              }
            />
            <DisplayError field={errors?.expirationDate} />
          </div>
          <div className='row mt-4'>
            <div className='col-12'>
              <a
                href='#'
                style={{ textDecoration: 'none' }}
                onClick={() => SetShowConditionsModal(true)}
              >
                Show terms
              </a>
              <div className='form-check'>
                <input
                  type='checkbox'
                  id='acceptTermsAndConditions'
                  className='form-check-input'
                  onChange={handleCheckboxOnChange}
                />
                <label className='form-check-label' htmlFor='acceptTermsAndConditions'>
                  Please confirm that you agree to our terms & conditions
                </label>
                <DisplayError field={errors?.acceptTermsAndConditions} />
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-end mt-5'>
          <SButtonDanger className='me-3' onClick={() => logout()}>
            Exit
          </SButtonDanger>
          <SButton type='submit'>
            Save Changes
          </SButton>
        </div>
      </form>
    )
  }

  return (
    <>
      {showSuccessModal ? (
        buildSuccessRegistrationModal()
      ) : (
        <>
          <Popup
            show={show}
            closeButton={false}
            title='Registration Form'
            handleClose={handleClose}
            content={renderForm()}
            backdrop='static'
          />
          {showConditionsModal ? buildTermsAndConditionsModal() : ''}
        </>
      )}
    </>
  )
}
