import styled from 'styled-components';
import { Button, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker'

export const enum Size {
    XS,
    S,
    M,
    L,
    XL
}

interface IconSize {
    readonly size?: Size;
}

export const SCanvasPrint = styled.div`
  @media screen {
      display: none;
  }
`

export const SIconButtonDark = styled(Button)`
padding:0;
background:none !important;
border:none !important;
    color:${(props): string => (props.theme.color.iconButton)};
    :hover {
       color: ${(props): string => (props.theme.color.iconButtonHoverDark)};
    }
    :active:focus {
        color: ${(props): string => (props.theme.color.iconButtonFocus)};
    }
`;

export const SIconButton = styled(Button)`
padding:0;
background:none !important;
border:none !important;
    color:${(props): string => (props.theme.color.iconButton)};
    :hover {
       color: ${(props): string => (props.theme.color.iconButtonHover)};
    }
    :active:focus {
        color: ${(props): string => (props.theme.color.iconButtonFocus)};
    }
`;

export const SButton = styled(Button)`
    border-color: ${(props): string => (props.theme.color.primaryButton.border)};
    background-color: ${(props): string => (props.theme.color.primaryButton.color)};
    font-family: ${(props): string => (props.theme.text.futura)};
    :hover {
        border-color:${(props): string => (props.theme.color.primaryButton.border)};
        background-color: ${(props): string => (props.theme.color.primaryButton.hover)};
    }
    :active:focus {
        background-color: ${(props): string => (props.theme.color.primaryButton.focus)};
    }
`;

export const SButtonDanger = styled(Button)`
    border-color: ${(props): string => (props.theme.color.dangerButton.border)};
    background-color: ${(props): string => (props.theme.color.dangerButton.color)};
    font-family: ${(props): string => (props.theme.text.futura)};
    :hover {
        --bs-btn-hover-border-color:${(props): string => (props.theme.color.dangerButton.border)};
        background-color: ${(props): string => (props.theme.color.dangerButton.hover)};
    }
    :active:focus {
        background-color: ${(props): string => (props.theme.color.dangerButton.focus)};
    }
`;

export const SButtonOutline = styled(Button)`
    border-color: ${(props): string => (props.theme.color.primaryButton.border)};
    background-color: transparent;
    font-family: ${(props): string => (props.theme.text.futura)};
    color: ${(props): string => (props.theme.color.periwinkle)};
     
    :hover {
        background-color: ${(props): string => (props.theme.color.primaryButton.hover)};
    }
    :active:focus {
        background-color: ${(props): string => (props.theme.color.primaryButton.focus)};
    }
`;

export const SButtonOutlineAlt = styled(Button)`
    border:none;
    background-color: transparent;
    font-family: ${(props): string => (props.theme.text.futura)};
    color: ${(props): string => (props.theme.color.periwinkle)};
     
    :hover {
        background-color: ${(props): string => (props.theme.color.primaryButton.hover)};
    }
    :active:focus {
        background-color: ${(props): string => (props.theme.color.primaryButton.focus)};
    }
`;

export const SLabel = styled.label`
    margin-bottom: 0px;
    color: ${(props): string => (props.theme.color.label)};
    font-family: ${(props): string => (props.theme.text.futura)};
    font-weight: 500;
`;

export const SHeaderDiv = styled.div`
height:3rem;
background:none;
display:flex;
align-items:center;
`

export const STitle = styled.h5`
    color:black;
    margin:0;
    font-family: ${(props): string => (props.theme.text.futura)};
`;

export const SDiv = styled.div`
    background:  ${props => (props.theme.color.background)};
    font-family: ${(props): string => (props.theme.text.futura)};
`

export const SAltDiv = styled.div`
    background:  ${props => (props.theme.color.backgroundPage)};
    font-family: ${(props): string => (props.theme.text.futura)};
`

export const LoginBox = styled(SAltDiv)`
.login-box {
    height: auto;
    background:  ${props => (props.theme.color.background)};
    text-align: center;
    box-shadow: ${props => (props.theme.shadow.box)};
    border-radius: ${props => (props.theme.border.radius)};
}
`;

export const Icon = styled.i<IconSize>`
${(props): string => {
    switch (props.size) {
    case Size.XS: return 'font-size: .5rem;';
    case Size.S: return 'font-size: 1rem;';
    case Size.M: return 'font-size: 1.5rem;';
    case Size.L: return 'font-size: 2rem;';
    case Size.XL: return 'font-size: 2.5rem;';
    }
    return 'font-size: 1.5rem;';
  }
}
`

export const SHR = styled.hr`
    background-color: ${props => (props.theme.color.periwinkle)};
    margin:0;
`

export const SLI = styled.li`
    border-radius: 20px;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    color: ${(props): string => (props.theme.color.periwinkle)};
    background:  ${props => (props.theme.color.background)};
    font-family: ${(props): string => (props.theme.text.futura)};
    font-weight: 500;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    display: block;
    margin-bottom:1em;

    :active,
    :focus,
    :hover {
        background-color: #ffffff60;
        color: ${(props): string => (props.theme.color.primaryButton.hover)};
        cursor: pointer;
    }
`

export const SUL = styled.ul`
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    display: flex;
    flex-direction: column!important;
    flex-wrap: wrap;
    padding-left: 0;    
    margin-bottom: 0;
    list-style: none;
    .active-option {
        background-color:${(props): string => (props.theme.color.periwinkle)};
        color: white;
        cursor: pointer;
    }
`

export const SModal = styled(Modal)`
    font-family: ${(props): string => (props.theme.text.futura)};
    --bs-modal-bg:${props => (props.theme.color.background)};
    --bs-modal-padding: 2rem;
    --bs-modal-header-border-color:  ${props => (props.theme.color.periwinkle)};

    .modal-header {
        background-color: ${props => (props.theme.color.backgroundPage)};
        justify-content: center!important;
    }
`

export const SDatePicker = styled(DatePicker)`
font-family: ${(props): string => (props.theme.text.futura)};
// font-size: ${(props): string => (props.theme.text.formFontSize)};
`

export const STableDiv = styled.table`
font-family: ${(props): string => (props.theme.text.futura)};
text-align: center;
vertical-align:middle;
--bs-table-hover-bg: ${(props): string => (props.theme.color.tableHover)} !important;
--bs-table-group-separator-color: ${(props): string => (props.theme.color.periwinkle)} !important;
`