import Modal from 'react-bootstrap/Modal'
import { Popup } from '../../../../common/modal'
import { StyleWrap } from './style'

type Props = {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

export const TermsAndConditionsModal = ({ show, setShow }: Props) => {
  const renderContent = () => {
    return (
      <>
        <StyleWrap className='container'>
          <div className='row mb-3 overflow-auto terms-container'>
            <h1 style={{ textAlign: 'center' }}>Periwinkle Fox Exchange</h1>
            <p style={{ textAlign: 'center' }}>Sell us your preloved children’s clothes.</p>
            <p style={{ textAlign: 'center' }}>
              Trade in your children’s lightly worn, outgrown clothing for something else in the
              next size up. Store credit can be used on all new retail and preloved items at our
              store.
            </p>
            <p>
              <ul>
                <li>We accept preloved (gently worn) clothing for children, size newborn to 10.</li>
                <li>
                  We buy on a walk-in basis between the hours of 10am-5pm, Tuesday-Saturday, at our
                  store located at 5910 Bryant Street (First Floor). No appointments necessary.
                </li>
                <li>We buy according to season, condition, inventory, and demand. </li>
                <li>
                  Product safety: we are not experts in the safety of apparel or gear, and we do not
                  repair items, so please do not provide us with anything if you have any reason to
                  believe that it could be unsafe for our customers.
                </li>
                <li>A government ID is required to sell in our store.</li>
                <li>Limit 1 bin of clothing to sell per day, per household.</li>
                <li>
                  You receive a quote for your trade in value within 48 hours. Once approved, store
                  credit will be created and posted to your account. Store credit is valid for 1
                  year.
                </li>
                <li>
                  Items must be in clean, sellable condition and free of pet hair and odor.
                  Absolutely no holes, broken zippers, or stains.
                </li>
                <li>
                  Brands: In order to maintain a high standard of quality, please note we limit the
                  quantity of items we accept from big box retailers like Target, Carter’s,
                  Gymboree, Macy’s. You can see a full list of brands we accept and do not accept on
                  our website. Please note, on occasion and depending on the store’s needs we may
                  accept items from big box retailers if the items are brand new with tags.
                </li>
                <li>
                  Items not selected for resale may be left for charity or picked up the next
                  business day. Unselected items not picked up by the end of the next business day
                  will be donated to charity (including the bag items that were brought in).
                </li>
                <li>
                  We assess items in-store only and will send you a quote for the trade in value via
                  email. Once items are approved and store credit is issued to the seller, the items
                  are transferred to our possession and added as sellable preloved inventory.
                </li>
              </ul>
            </p>
            <p>Liability</p>
            <p>
              <ul>
                <li>
                  Periwinkle Fox does not make any warranties or representations about the safety or
                  suitability of secondhand items. Periwinkle Fox has no experience or expertise in
                  manufacturing, repairing, or inspecting merchandise for safety, ease of use, or
                  fitness for a particular purpose and therefore disclaims any responsibility for
                  recalls, defective merchandise, or safe usage for any purpose.
                </li>
              </ul>
            </p>
            <p>Updates and Changes </p>
            <p>
              <ul>
                <li>
                  Periwinkle Fox reserves the right to alter this policy at any time. It is the
                  seller’s responsibility to check the current agreement on our website for any
                  updates (periwinklefox.com).
                </li>
              </ul>
            </p>
            <p>
              <b>
                Your agreement indicates your acknowledgement and agreement to all the terms of this
                agreement and the statements above.
              </b>
            </p>
          </div>
        </StyleWrap>
        <Modal.Footer></Modal.Footer>
      </>
    )
  }

  return (
    <>
      <Popup
        show={show}
        title='Terms & Conditions'
        handleClose={() => setShow(false)}
        content={renderContent()}
        size='lg'
      />
    </>
  )
}
