import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import { Item } from '../../../../../services/items/types'
import { Table } from '../../../../common/table'
import { Column } from '../../../../common/table/types'
import { Tag } from '../../../../../services/tags/types'
import { CreateEditModal } from '../../modals/create'
import { GetById, GetItems } from '../../../../../services/tags'
import { SDiv, STitle, SHR, SLabel } from '../../../../../theme/commonComponents'

export const TagDetails = (): JSX.Element => {
  const [tag, setTag] = useState<Tag>()
  const [items, setItems] = useState<Item[]>([])
  const [showCreateEditModal, setShowCreateEditModal] = useState<boolean>(false)
  const [onLoading, setOnLoading] = useState<boolean>(false)
  let { tagId } = useParams()

  const getTag = (): void => {
    try {
      GetById(tagId ?? '').then((data) => {
        setTag(data)
      })

      GetItems(tagId ?? '').then((data) => {
        setItems(data)
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
      } else {
        console.log('unexpected error:', error)
      }
    }
  }

  useEffect((): void => {
    getTag()
  }, [])

  useEffect((): void => {
    if (onLoading) {
      getTag()
      setOnLoading(false)
    }
  }, [onLoading])

  const buildItemsTable = (): JSX.Element => {
    const columns: Column[] = [
      { title: 'Id', fieldName: 'id', hidden: true, sortable: false },
      { title: 'Name', fieldName: 'name', sortable: false },
      { title: 'Picture', fieldName: 'imageUrl', sortable: true, isImage: true },
      // { title: 'Cash offer', fieldName: 'cashOffer', sortable: true },
      { title: 'Store credit offer', fieldName: 'storeCreditOffer', sortable: true },
    ]

    return (
      <Table
        columns={columns}
        data={items}
        page={0}
        perPage={0}
        setPage={() => { }}
        totalRecords={0}
        hidePagination
      />
    )
  }

  const buildCreateEditModal = (): JSX.Element => {
    return (
      <CreateEditModal
        tag={tag}
        editMode={true}
        show={showCreateEditModal}
        setShow={setShowCreateEditModal}
        setOnLoading={setOnLoading}
      />
    )
  }

  const buildTagDetailsHeader = (): JSX.Element => {
    return (
      <>
        <div className='row mb-3'>
          <div className='col-12'>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <STitle>Tag details</STitle>
            </div>
            <SHR />
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-sm-6 col-12 col-lg-3'>
            <SLabel htmlFor='name'>Name</SLabel>
            <input className='form-control form-control-sm' type='text' id='name' value={tag?.name ?? 0} disabled />
          </div>

          <div className='col-sm-6 col-12 col-lg-3'>
            <SLabel htmlFor='buyDate'>Type</SLabel>
            <input className='form-control form-control-sm' type='text' id='type' value={tag?.type ?? 0} disabled />
          </div>
        </div>

        {/* TODO: DO NOT REMOVE THIS. TO BE IMPLEMENTED WITH NEW CHANGES */}
        {/*  <div className='container mb-3'>
          
          <ButtonContainer>
            <button
              className='btn btn-primary'
              id='openEditModal'
              onClick={() => setShowCreateEditModal(true)}
            >
              Edit
            </button>
          </ButtonContainer> 
        </div>*/}
      </>
    )
  }

  return (
    <>
      <SDiv className='d-flex flex-column p-4 pt-3 mb-5 h-100'>
        {buildTagDetailsHeader()}
        <div className='d-flex flex-row justify-content-between align-items-center mt-5'>
          <STitle>Items</STitle>
        </div>
        <SHR />
        <div className='mt-4 flex-fill bg-white rounded p-3'>
          {buildItemsTable()}
        </div>
      </SDiv >
      {showCreateEditModal ? buildCreateEditModal() : ''}
    </>
  )
}
