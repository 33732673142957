import Select, { PropsValue } from 'react-select'
import { Option } from '../services/common/types'
import AsyncSelect from 'react-select/async'
import theme from './theme'

interface SelectProps {
    options: Option[],
    onChange: (option: Option | null) => void
    defaultValue?: PropsValue<Option>
}

export default function SSelect(props: SelectProps) {
  return (
    <Select
      styles={{
        control: (styles) => ({
          ...styles,
          minHeight: 31,
          height: 31
        }),
        dropdownIndicator:
                    (styles) => ({
                      ...styles,
                      paddingTop: 1,
                      paddingBottom: 1,
                    }),
        valueContainer: (provided) => ({
          ...provided,
          height: '30px',
          padding: '0 6px'
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: '30px',
        }),
      }}
      isClearable
      options={props.options}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
    />
  )
}

interface ASelectProps {
    isDisabled?: boolean
    defaultOptions?: boolean
    isMulti?: boolean
    onChange?: any
    defaultValue?: PropsValue<Option> | undefined
    value?: PropsValue<Option> | undefined
    onInputChange?: (newValue: string) => string
    loadOptions?: (inputValue: string) => Promise<Option[]>
}

export function SASelect(props: ASelectProps) {
  return (
    <AsyncSelect
      styles={{
        control: (styles) => ({
          ...styles,
          minHeight: 31,
          height: 31,
        }),
        dropdownIndicator:
                    (styles) => ({
                      ...styles,
                      paddingTop: 1,
                      paddingBottom: 1,
                    }),
        valueContainer: (provided) => ({
          ...provided,
          height: '30px',
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: '30px',
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          height: '22px',
          fontSize: '1rem',
          margin: 0,
          padding: 0,
          color: 'white'
        }),
        multiValueRemove: (provided) => ({
          ...provided,
          padding: '0px 2px 0px 2px',
          margin: '0px 0px 0px 3px',
          color: 'white',
        }),
        multiValue: (provided) => ({
          ...provided,
          background: theme.color.periwinkle,
          padding: 0
        }),
        input: (provided) => ({
          ...provided,
          margin: 0,
          padding: 0
        }),
      }}
      value={props.value}
      isDisabled={props.isDisabled}
      isMulti={props.isMulti}
      cacheOptions
      isClearable
      onChange={props.onChange}
      onInputChange={props.onInputChange}
      loadOptions={props.loadOptions}
      defaultValue={props.defaultValue}
      defaultOptions={props.defaultOptions}
    />
  )
}