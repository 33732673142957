import client from '../api/configs'
import { ImageDTO, PatchItemTagsDTO, TagDTO } from '../generated'
import { mapToImageList, mapToItem, mapToItemList } from './mapper'
import { Item, Image } from './types'

export const GetAll = async (
  offset?: number,
  limit?: number,
  filterKey?: Array<string>,
  filterValue?: Array<string>,
): Promise<Item[]> => {
  const data = await client.getItems({ offset, limit, filterKey, filterValue })
  return mapToItemList(data)
}

export const GetById = async (id: string): Promise<Item> => {
  const data = await client.getItem({ id })
  return mapToItem(data)
}

export const GetTags = async (id: string): Promise<TagDTO[]> => {
  const data = await client.getItemTags({ id })
  return data
}

/* export const AddTags = async (id:string): Promise<void> => {
  await client.
} */

export const GetImages = async (id: string): Promise<Image[]> => {
  const data = await client.getItemImages({ id })
  return mapToImageList(data)
}

export const UploadImage = async (image: Blob): Promise<ImageDTO> => {
  const data = await client.uploadImage({ formData: { file: image } })
  return data
}

export const Patch = async (id: string, request: string): Promise<void> => {
  await client.updateItem({ id, requestBody: request })
}

export const PatchTags = async(id:string, request: PatchItemTagsDTO):Promise<void> => {
  await client.updateItemTags({id, requestBody:request })
}

export const Delete = async (id: string): Promise<void> => {
  await client.deleteItem({ id })
}
