import { FormData } from '../../components/modules/buyouts/modals/addItem/validation/schema'
import { ImageDTO, ItemDTO, PatchItemTagsDTO, TagDTO } from '../generated'
import { TagTypes } from '../tags/types'
import { Item, Image } from './types'
import * as jsonpatch from 'fast-json-patch'

export const mapToImage = (data: ImageDTO): Image => {
  return {
    imageBlobId: data.image_blob_id,
    imageUrl: data.image_url,
    id: data.id ?? '',
    itemId: data.item_id ?? '',
  }
}

export const mapToImageList = (data: ImageDTO[]): Image[] => {
  let list: Image[] = []

  data.forEach((element) => {
    list.push(mapToImage(element))
  })

  return list
}

export const mapToItem = (data: ItemDTO): Item => {
  // let images = mapToImageList(data.images ?? [])

  return {
    id: data.id ?? '',
    name: data.name ?? '',
    buyoutId: data.buyout_id,
    cashOffer: data.cash_offer ?? 0,
    storeCreditOffer: data.store_credit_offer ?? 0,
    images: [],
    userId: data.user_id,
  }
}

export const mapToItemList = (data: ItemDTO[]): Item[] => {
  let list: Item[] = []

  data.forEach((element) => {
    list.push(mapToItem(element))
  })

  return list
}

export const mapFormDataToItem = (data: FormData): Item => {
  let images = new Array<Image>()
  /*  data.images.forEach((img) => {
    images.push({
      imageBlobId: img.imageBlobId,
      imageUrl: img.imageUrl,
      id: '',
      itemId: '',
    })
  }) */

  return {
    id: '',
    name: '',
    buyoutId: data.buyoutId,
    // cashOffer: Number(data.cashOffer),
    cashOffer: 0,
    storeCreditOffer: Number(data.storeCreditOffer),
    userId: data.userId,
    images: images,
  }
}

export const mapFormDataToItemDTO = (data: FormData): ItemDTO => {
  let images = new Array<ImageDTO>()
  /*  data.images.forEach((img) => {
    images.push({
      image_blob_id: img.imageBlobId,
      image_url: img.imageUrl,
    })
  }) */
  let tags: string[] = []
  tags.push(data.sizeTag)
  tags.push(data.brandTag)
  tags.push(data.categoryTag)
  tags.push(data.conditionTag)

  if (data.genderTag !== null && data.genderTag !== undefined && data.genderTag !== '') {
    tags.push(data.genderTag)
  }
  if (data.colorTags !== null && data.colorTags !== undefined && data.colorTags.length > 0) {
    data.colorTags.forEach((x) => {
      if (x !== null && x !== undefined && x !== '') {
        tags.push(x)
      }
    })
  }
  if (data.patternTag !== null && data.patternTag !== undefined && data.patternTag !== '') {
    tags.push(data.patternTag)
  }

  return {
    buyout_id: data.buyoutId,
    // cash_offer: Number(data.cashOffer ?? '0'),
    store_credit_offer: Number(data.storeCreditOffer ?? '0'),
    user_id: data.userId,
    images: images,
    tags: tags,
  }
}

//TODO: study how to handle multiple images update etc...
export const mapToPatchItemRequest = (item: Item, data: FormData): string => {
  var observer = jsonpatch.observe<Item>(item)

  /*TODO: DO NOT REMOVE, TO BE IMPLEMENTED WHEN BE IS READY*/
  /* console.log(item.images)
  console.log(data.images)
  if (item.images[0].imageBlobId !== data.images[0].imageBlobId) {
    item.images[0].imageBlobId = data.images[0].imageBlobId
    item.images[0].imageUrl = data.images[0].imageUrl
  } */

  /* const cashOffer = Number(data.cashOffer)
  if (cashOffer != null && item.cashOffer != cashOffer) {
    item.cashOffer = cashOffer.valueOf()
  } */

  const storeOffer = Number(data.storeCreditOffer)
  if (storeOffer != null && item.storeCreditOffer != storeOffer) {
    item.storeCreditOffer = storeOffer.valueOf()
  }

  // handle tag changes
  /*   for (let i = 0; i < tags.length; i++) {
    const tag = tags[0]
    if (tag.type == 'Size') {
      if (tag.id !== data.sizeTag) {
        tag.id = data.sizeTag
      }
    }
    if (tag.type == 'Brand') {
      if (tag.id !== data.brandTag) {
        tag.id = data.brandTag
      }
      if (tag.id !== data.categoryTag) {
        tag.id = data.categoryTag
      }
      if (tag.id !== data.conditionTag) {
        tag.id = data.conditionTag
      }
    }
    if (tag.type == 'Category') {
      if (tag.id !== data.categoryTag) {
        tag.id = data.categoryTag
      }
    }
    if (tag.type == 'Condition') {
      if (tag.id !== data.conditionTag) {
        tag.id = data.conditionTag
      }
    }
  } */

  var patch = jsonpatch.generate(observer)
  return JSON.stringify(patch)
}

export const maptToPatchItemTagsDTO = (currentTags: TagDTO[], data: FormData): PatchItemTagsDTO => {
  const patchObj: PatchItemTagsDTO = {}
  patchObj.added = []
  patchObj.removed = []

  for (let i = 0; i < currentTags.length; i++) {
    const tag = currentTags[i]

    switch (tag.type) {
    case TagTypes.SIZE:
      patchTag(patchObj, tag, data.sizeTag)
      break
    case TagTypes.BRAND:
      patchTag(patchObj, tag, data.brandTag)
      break
    case TagTypes.CATEGORY:
      patchTag(patchObj, tag, data.categoryTag)
      break
    case TagTypes.GENDER:
      patchTag(patchObj, tag, data.genderTag)
      break
    case TagTypes.CONDITION:
      patchTag(patchObj, tag, data.conditionTag)
      break
    case TagTypes.PATTERN:
      patchTag(patchObj, tag, data.patternTag)
      break
    default:
      break
    }
  }

  patchMultiTag(
    patchObj,
    currentTags.filter((x) => x.type == TagTypes.COLOR).map((x) => x.id ?? ''),
    data.colorTags,
  )

  return patchObj
}

const patchTag = (patchObj: PatchItemTagsDTO, tag: TagDTO, newTagId: string): void => {
  if (tag.id !== newTagId) {
    if (tag.id !== '' && tag.id !== undefined && newTagId !== '' && newTagId !== undefined) {
      patchObj.added?.push(newTagId)
      patchObj.removed?.push(tag.id)
    } else if (
      tag.id !== '' &&
      tag.id !== undefined &&
      (newTagId === '' || newTagId === undefined)
    ) {
      patchObj.removed?.push(tag.id)
    } else if (
      newTagId !== '' &&
      newTagId !== undefined &&
      (tag.id === '' || tag.id === undefined)
    ) {
      patchObj.added?.push(newTagId)
    }
  }
}

const patchMultiTag = (
  patchObj: PatchItemTagsDTO,
  existingTagsIds: string[],
  newTagsIds: string[],
): void => {
  patchObj.added?.push(
    ...newTagsIds.filter((x) => x !== '' && !existingTagsIds.includes(x)).map((x) => x),
  )
  patchObj.removed?.push(
    ...existingTagsIds.filter((x) => x !== '' && !newTagsIds.includes(x)).map((x) => x),
  )
}
