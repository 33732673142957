import { createBrowserRouter } from 'react-router-dom'
import { ROUTES } from './routes'
import { List as ListBuyouts } from '../components/modules/buyouts/list'
import { List as ListTags } from '../components/modules/tags/list'
import { BuyoutDetails as ViewBuyout } from '../components/modules/buyouts/view/details'
import { TagDetails as ViewTag } from '../components/modules/tags/view/details'
import { App } from '../App'
import { Account } from '../components/modules/account'
import { SingleBuyView } from '../components/modules/buyouts/view/singleBuy'

export const router = createBrowserRouter([
  {
    path: ROUTES.ROOT,
    element: <App />,
    children: [
      {
        path: ROUTES.ACCOUNT.ROOT,
        element: <Account />,
      },
      {
        path: ROUTES.BUYOUTS.LIST,
        element: <ListBuyouts />,
      },
      {
        path: ROUTES.BUYOUTS.VIEW + '/:buyoutId',
        element: <ViewBuyout />,
      },
      {
        path: ROUTES.BUYOUTS.SINGLEBUY + '/:buyoutId',
        element: <SingleBuyView />,
      },
      {
        path: ROUTES.TAGS.LIST,
        element: <ListTags />,
      },
      {
        path: ROUTES.TAGS.VIEW + '/:tagId',
        element: <ViewTag />,
      },
    ],
  },
])
