import { ToastContainer, toast, TypeOptions, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Toast as ToastState } from '../../../store/app/types'
import { State } from '../../../store'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

export const Toast = () => {
  const toastReceived = useSelector<State, ToastState>((state) => state.app.toast)

  const notify = (toastReceived: ToastState) =>
    toast(toastReceived.title, { type: toastReceived.type as TypeOptions })

  useEffect(() => {
    if (toastReceived != undefined && toastReceived.title !== '' && toastReceived.type !== '')
      notify(toastReceived)
  }, [toastReceived])

  return (
    <ToastContainer
      transition={Zoom}
      position='top-right'
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme='light'
    />
  )
}
