import * as yup from 'yup'

export const formDataSchema = yup
  .object({
    id: yup.string().required(),
    firstName: yup.string().required().label('First name'),
    lastName: yup.string().required().label('Last name'),
    address: yup.string().required().label('Address'),
    city: yup.string().required().label('City'),
    state: yup.string().required().label('State'),
    zipCode: yup
      .string()
      .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, { message: 'Invalid ZIP/Postal code format' })
      .required()
      .label('ZIP/Postal code'),
    phone: yup.string().required().label('Phone'),
    email: yup.string().required().label('Email'),
  })
  .required()

export interface FormData extends yup.InferType<typeof formDataSchema> {}
