import { ReactNode } from 'react'
import { SModal, STitle } from '../../../theme/commonComponents'

type Props = {
  show: boolean
  handleClose: () => void
  title: string
  content: ReactNode
  closeButton?: boolean
  backdrop?: boolean | 'static' | undefined
  size?: 'lg' | 'sm' | 'xl' | undefined
}

export const Popup = ({
  content,
  title,
  show,
  handleClose,
  backdrop = true,
  closeButton = true,
  size = 'lg',
}: Props) => {
  return (
    <>
      <SModal show={show} onHide={handleClose} backdrop={backdrop} size={size}>
        <SModal.Header closeButton={closeButton}>
          <SModal.Title>
            <STitle className='modal-title'>{title}</STitle>
          </SModal.Title>
        </SModal.Header>
        <SModal.Body>{content}</SModal.Body>
      </SModal>
    </>
  )
}
