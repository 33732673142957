import { PeriwinkleApiClient } from '../generated';
import { GetTokenSilentlyOptions } from '@auth0/auth0-react';
import { Autho0Config } from '../../config';

const client = new PeriwinkleApiClient()

export const setToken = (getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>) : void => {
  client.request.config.TOKEN = async (): Promise<string> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: Autho0Config.audience
      },
    });

    return accessToken
  }
  client.request.config.WITH_CREDENTIALS = true;
}

export default client.default;
