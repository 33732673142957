import { useEffect, useState } from 'react'
import axios from 'axios'
import { Table } from '../../common/table'
import { Action, Column } from '../../common/table/types'
import { AddChildModal } from './modals/addChild'
import { CreateEditModal } from './modals/create'
import { Delete } from '../../../services/children'
import { Child } from '../../../services/children/types'
import { GetById, GetChildren } from '../../../services/account'
import { User } from '../../../services/account/types'
import { useSelector } from 'react-redux'
import { State } from '../../../store'
import { User as UserState } from '../../../store/app/types'
import { USStateOptions } from '../../../consts/usStates'
import { Icon, SIconButtonDark, STitle, SLabel, SDiv, SHR } from '../../../theme/commonComponents'

export const Account = () => {
  const [user, setUser] = useState<User>({} as User)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [children, setChildren] = useState<Child[]>([])
  const [showAddChild, setShowAddChild] = useState<boolean>(false)
  const [onLoading, setOnLoading] = useState<boolean>(false)
  const userState = useSelector<State, UserState>((state) => state.app.user)

  const getChildren = () => {
    try {
      GetChildren(userState.id).then((data: Child[]) => {
        setChildren(data)
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
      } else {
        console.log('unexpected error:', error)
      }
    }
  }

  const getAccountInfo = () => {
    try {
      GetById(userState.id).then((data: User) => {
        setUser(data)
        getChildren()
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
      } else {
        console.log('unexpected error:', error)
      }
    }
  }

  useEffect(() => {
    getAccountInfo()
  }, [])

  useEffect(() => {
    if (onLoading) {
      getChildren()
      setOnLoading(false)
    }
  }, [onLoading])

  const handleRemoveChild = (id: string) => {
    try {
      let arr = [...children]
      let deleted = arr.filter((item) => item.id === id)

      Delete(deleted[0].id).then(() => {
        console.log('Success')
        setOnLoading(true)
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
        return error.message
      } else {
        console.log('unexpected error:', error)
        return 'An unexpected error occured'
      }
    }
  }

  const handleActions = (type: string, id: string) => {
    if (type == 'Delete') {
      handleRemoveChild(id)
    }
  }

  const buildChildTable = () => {
    const columns: Column[] = [
      { title: 'Id', fieldName: 'id', hidden: true, sortable: false },
      { title: 'First name', fieldName: 'firstName', sortable: true },
      { title: 'Birthday', fieldName: 'birthday', sortable: true },
    ]

    const actions: Action[] = [{ label: 'Delete', fieldName: 'id', type: 'Delete' }]

    return (
      <Table
        columns={columns}
        data={children}
        actions={actions}
        page={0}
        perPage={0}
        setPage={() => { }}
        totalRecords={0}
        handleActions={handleActions}
        hidePagination
      />
    )
  }

  const buildCreateEditModal = () => {
    return (
      <CreateEditModal
        editMode={true}
        show={showEditModal}
        setShow={setShowEditModal}
        user={user}
      />
    )
  }

  const buildAddChildModal = () => {
    return (
      <AddChildModal
        show={showAddChild}
        setShow={setShowAddChild}
        setOnLoading={setOnLoading}
        userId={userState.id}
      />
    )
  }

  return (
    <>
      <SDiv className='d-flex flex-column p-4 pt-3 mb-5 h-100'>
        <div className='row mb-3'>
          <div className='col-12'>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <STitle>Account details</STitle>
              <SIconButtonDark title='Edit' id='openEditModal' className='btn p-0 m-0' onClick={() => setShowEditModal(true)}>
                <Icon className='bi-pencil-square'></Icon>
              </SIconButtonDark>
            </div>
            <SHR />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-sm-6 col-12 col-lg-3'>
            <SLabel htmlFor='firstName'>First name</SLabel>
            <input type='text' className='form-control form-control-sm' id='firstName' value={user?.firstName ?? ''} disabled />
          </div>
          <div className='col-sm-6 col-12 col-lg-3'>
            <SLabel htmlFor='lastName'>Last name</SLabel>
            <input type='text' className='form-control form-control-sm' id='lastName' value={user?.lastName ?? ''} disabled />
          </div>
          <div className='col-sm-6 col-12 col-lg-3'>
            <SLabel htmlFor='phone'>Phone</SLabel>
            <input type='text' className='form-control form-control-sm' id='phone' value={user?.phone ?? ''} disabled />
          </div>
          <div className='col-sm-6 col-12 col-lg-3'>
            <SLabel htmlFor='email'>Email address</SLabel>
            <input type='text' className='form-control form-control-sm' id='email' value={user?.email ?? ''} disabled />
          </div>
        </div>

        <div className='row mb-3'>
          <div className='col-sm-6 col-12 col-lg-3'>
            <SLabel htmlFor='address'>Address</SLabel>
            <input type='text' className='form-control form-control-sm' id='address' value={user?.address ?? ''} disabled />
          </div>
          <div className='col-sm-6 col-12 col-lg-3'>
            <SLabel htmlFor='city'>City</SLabel>
            <input type='text' className='form-control form-control-sm' id='city' value={user?.city ?? ''} disabled />
          </div>
          <div className='col-sm-6 col-12 col-lg-3'>
            <SLabel htmlFor='state'>State</SLabel>
            {user && (
              <input
                type='text'
                id='state'
                value={USStateOptions.filter((x) => x.value == user?.state ?? '')[0]?.label}
                disabled
                className='form-control form-control-sm'
              />
            )}
          </div>
          <div className='col-sm-6 col-12 col-lg-3'>
            <SLabel htmlFor='zipCode'>ZIP/Postal code</SLabel>
            <input type='text' className='form-control form-control-sm' id='zipCode' value={user?.zipCode} disabled />
          </div>
        </div>
        <div className="d-flex mt-5 justify-content-between align-items-center">
          <STitle>Children</STitle>
          <SIconButtonDark title='Add Child' className='btn p-0 m-0' onClick={() => setShowAddChild(true)}>
            <Icon className='bi-plus-square'></Icon>
          </SIconButtonDark>
        </div>
        <SHR />
        <div className='mt-4 flex-fill bg-white rounded p-3'>
          {buildChildTable()}
        </div>
      </SDiv>
      {showEditModal ? buildCreateEditModal() : ''}
      {showAddChild ? buildAddChildModal() : ''}
    </>
  )
}
