import React, { forwardRef } from 'react'
import JsBarcode from 'jsbarcode'
import { SCanvasPrint } from '../../../../theme/commonComponents'

type Props = {
  clientName: string
  periwinkleId: string
  date: Date | undefined
}

export type Ref = HTMLButtonElement

export const PrintLabel = forwardRef<Ref, Props>((props, ref) => {
  const canvas = document.createElement('canvas')
  JsBarcode(canvas, props.periwinkleId, { height: 50 })
  const src = canvas.toDataURL()
  return (
    <SCanvasPrint
      ref={ref as React.RefObject<HTMLDivElement>}
      style={{ width: '80mm', height: '60mm' }}
    >
      <table
        className='table table-bordered mb-0'
        style={{ marginTop: '10px', textAlign: 'center' }}
      >
        <tr>
          <td style={{ fontSize: '14px' }}>{props.clientName}</td>
        </tr>
        <tr style={{ margin: '0 auto', padding: '0 auto' }}>
          <td style={{ padding: '1' }} align='center' colSpan={2}>
            <img style={{ imageRendering: 'pixelated' }} src={src} />
            <br style={{ textAlign: 'center', padding: '1' }} />
          </td>
        </tr>
        <tr style={{ textAlign: 'center' }}>
          <td style={{ fontSize: '14px' }}>{props.date?.toLocaleDateString()}</td>
        </tr>
      </table>
    </SCanvasPrint>
  )
})
