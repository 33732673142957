import { Icon, SIconButton } from '../../../theme/commonComponents'
import { useLogout } from '../../../hooks/useLogout'

const LogoutButton = () => {
  const logout = useLogout()

  return (
    <SIconButton title='Logout' onClick={() => logout()}>
      <Icon className='bi-box-arrow-right'></Icon>
    </SIconButton>
  )
}

export default LogoutButton
