import { FormData } from '../../components/modules/tags/modals/create/validation/schema'
import { AddCategoryDTO, CategoryLevelDTO, TagDTO } from '../generated'
import * as jsonpatch from 'fast-json-patch'
import { Tag } from './types'
import { Option } from '../common/types'

export const mapToTag = (data: TagDTO): Tag => {
  return {
    id: data.id ?? '',
    name: data.name ?? '',
    type: data.type ?? '',
  }
}

export const mapToTagDTO = (data: Tag): TagDTO => {
  return {
    id: data.id,
    name: data.name,
    type: data.type,
  }
}

export const mapToTagList = (data: TagDTO[]): Tag[] => {
  let list: Tag[] = []

  data.forEach((element) => {
    list.push(mapToTag(element))
  })

  return list
}

export const mapToCreateTagRequest = (data: FormData): TagDTO => {
  return {
    name: data.name,
    type: data.type,
  }
}

export const mapToCreateCategoryTagRequest = (data: FormData): AddCategoryDTO => {
  let obj: AddCategoryDTO = {
    name: data.name,
    level1: {
      name: data.l1_category_name ?? '',
      id: data.l1_category_id,
    },
  }

  if (
    data.l2_category_name !== undefined &&
    data.l2_category_name !== '' &&
    data.l2_category_id !== undefined &&
    data.l2_category_id !== ''
  ) {
    obj.level2 = {
      name: data.l2_category_name ?? '',
      id: data.l2_category_id,
    }
  }

  return obj
}

export const mapToPatchTagRequest = (tag: Tag, data: FormData): string => {
  var observer = jsonpatch.observe<Tag>(tag)

  if (tag.name != data.name) {
    tag.name = data.name
  }

  if (tag.type != data.type) {
    tag.type = data.type
  }

  var patch = jsonpatch.generate(observer)
  return JSON.stringify(patch)
}

export const mapTagDTOToOption = (data: TagDTO[]): Option[] => {
  return data.map((elem) => ({ value: elem.id ?? '', label: elem.name ?? '' }))
}

export const mapCategoryToOption = (data: CategoryLevelDTO[]): Option[] => {
  return data.map((elem) => ({ value: elem.id ?? '', label: elem.name ?? '' }))
}

export const mapTagToOption = (data: Tag[]): Option[] => {
  return data.map((elem) => ({ value: elem.id, label: elem.name }))
}
