import { Auth0Provider, AppState } from '@auth0/auth0-react';
import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { Autho0Config } from './config';

interface Auth0ProviderWithNavigateProps {
    children: React.ReactNode;
}

export const Auth0ProviderWithNavigate = ({
  children,
}: PropsWithChildren<Auth0ProviderWithNavigateProps>): JSX.Element | null => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(Autho0Config.domain && Autho0Config.clientId && process.env.REACT_APP_AUTH0_CALLBACK_URL && Autho0Config.audience)) {
    console.error('MISSING AUTH0 CREDENTIALS!')
    return null;
  }
  console.log(process.env.REACT_APP_AUTH0_CALLBACK_URL)
  return (
    <Auth0Provider
      domain={Autho0Config.domain}
      clientId={Autho0Config.clientId}
      authorizationParams={{
        audience: Autho0Config.audience,
        redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
