// TODO: improve structure
export const ROUTES = {
  ROOT: '/',
  ACCOUNT: {
    ROOT: '/retail/accounts',
  },
  BUYOUTS: {
    ROOT: '/retail/buyouts',
    LIST: '/retail/buyouts/list',
    CREATE: '/retail/buyouts/create',
    VIEW: '/retail/buyouts/view',
    SINGLEBUY: '/retail/buyouts/buy',
  },
  TAGS: {
    ROOT: '/retail/tags',
    LIST: '/retail/tags/list',
    CREATE: '/retail/tags/create',
    VIEW: '/retail/tags/view',
  },
}

export const ROUTES2 = {
  ROOT: {
    path: '/',
    description: 'Home',
    ACCOUNT: {
      path: '/account',
      description: 'Account',
    },
    BUYOUTS: {
      path: '/buyouts',
      description: 'Buyouts',
      LIST: {
        path: '/list',
        description: 'Buyouts',
      },
      CREATE: {
        path: '/create',
        description: 'New Buyout',
      },
      VIEW: {
        path: '/view',
        description: 'Buyout details',
      },
    },
  },
}
