import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import { BuyoutHeader } from '../common/buyoutHeader'
import { AddEditItemModal } from '../../modals/addItem'
import { Item } from '../../../../../services/items/types'
import { Delete } from '../../../../../services/items'
import { Table } from '../../../../common/table'
import { Action, Column } from '../../../../common/table/types'
import {
  ChangeStatus,
  GetById,
  GetItems,
  SendItemToLightspeed,
} from '../../../../../services/buyouts'
import { GetById as GetUserbyId } from '../../../../../services/account'
import { Buyout } from '../../../../../services/buyouts/types'
import { CreateEditModal } from '../../modals/create'
import { useSelector } from 'react-redux'
import { User } from '../../../../../store/app/types'
import { State } from '../../../../../store'
import { buyoutStatus } from '../../../../../services/buyouts/mapper'
import { useSetToken } from '../../../../../hooks/useSetToken'
import ReactToPrint from 'react-to-print'
import { PrintLabel } from '../../printLabel'
import { Icon, SButton, SButtonDanger, SDiv, SHR, SIconButtonDark, STitle, Size } from '../../../../../theme/commonComponents'

export const BuyoutDetails = (): JSX.Element => {
  const [buyout, setBuyout] = useState<Buyout>({} as Buyout)
  const [items, setItems] = useState<Item[]>([])
  const [showAddEditItemModal, setShowAddEditItemModal] = useState<{
    active: boolean
    editMode: boolean
    itemId: string | undefined
  }>({ active: false, editMode: false, itemId: undefined })
  const [showCreateEditModal, setShowCreateEditModal] = useState<boolean>(false)
  const [onLoading, setOnLoading] = useState<boolean>(false)
  const [userName, setUserName] = useState<string>('')
  let { buyoutId } = useParams()
  const user = useSelector<State, User>((state) => state.app.user)
  const [statusBtn, setStatusBtn] = useState<{ description: string; nextStatus: string }[]>([])
  useSetToken()

  useEffect((): void => {
    getBuyout()
  }, [])

  useEffect((): void => {
    if (onLoading) {
      getBuyout()
      setOnLoading(false)
    }
  }, [onLoading])

  const getBuyout = (): void => {
    try {
      GetById(buyoutId ?? '').then((buyoutData) => {
        setBuyout(buyoutData)
        changeStatus(buyoutData.status)
        GetUserbyId(buyoutData.user?.id ?? '').then((userData) => {
          setUserName(userData.firstName + ' ' + userData.lastName)
        })
      })

      GetItems(buyoutId ?? '').then((data) => {
        setItems(data)
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
      } else {
        console.log('unexpected error:', error)
      }
    }
  }

  const changeStatus = (currentStatus: string): void => {
    switch (currentStatus) {
    case buyoutStatus.NOT_STARTED:
      setStatusBtn([
        { description: 'Send for review', nextStatus: buyoutStatus.IN_REVIEW },
        { description: '', nextStatus: '' },
      ])
      break
    case buyoutStatus.IN_REVIEW:
      setStatusBtn([
        {
          description: 'Send for customer review',
          nextStatus: buyoutStatus.SUBMITTED_FOR_CUSTOMER_APPROVAL,
        },
        { description: '', nextStatus: '' },
      ])
      break
    case buyoutStatus.CUSTOMER_APPROVED:
      setStatusBtn([
        {
          description: 'Complete buyout',
          nextStatus: buyoutStatus.COMPLETED,
        },
        { description: '', nextStatus: '' },
      ])
      break
    case buyoutStatus.CUSTOMER_REJECTED:
      setStatusBtn([
        {
          description: 'Complete buyout',
          nextStatus: buyoutStatus.COMPLETED,
        },
        {
          description: 'Resend for review',
          nextStatus: buyoutStatus.SUBMITTED_FOR_CUSTOMER_APPROVAL,
        },
      ])
      break
    default:
      setStatusBtn([
        { description: '', nextStatus: '' },
        { description: '', nextStatus: '' },
      ])
      break
    }
  }

  const handleChangeStatus = (nextStatus: string): string | undefined => {
    try {
      ChangeStatus(buyout?.id, nextStatus).then(() => {
        setOnLoading(true)
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
        return error.message
      } else {
        console.log('unexpected error:', error)
        return 'An unexpected error occured'
      }
    }
  }

  const handleCompleteAndItemImport = (): string | undefined => {
    try {
      SendItemToLightspeed(buyout?.id).then(() => {
        setOnLoading(true)
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
        return error.message
      } else {
        console.log('unexpected error:', error)
        return 'An unexpected error occured'
      }
    }
  }

  const handleRemoveItem = (id: string): string | undefined => {
    try {
      let arr = [...items]
      let deleted = arr.filter((item) => item.id === id)

      Delete(deleted[0].id).then(() => {
        console.log('Success')
        setOnLoading(true)
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
        return error.message
      } else {
        console.log('unexpected error:', error)
        return 'An unexpected error occured'
      }
    }
  }

  const handleEditItem = (id: string): void => {
    setShowAddEditItemModal({ active: true, editMode: true, itemId: id })
  }

  const handleActions = (type: string, id: string): void => {
    if (type == 'Delete') {
      handleRemoveItem(id)
    }
    if (type == 'Edit') {
      handleEditItem(id)
    }
  }

  const buildItemsTable = (): JSX.Element => {
    const columns: Column[] = [
      { title: 'Id', fieldName: 'id', hidden: true, sortable: false },
      { title: 'Name', fieldName: 'name', sortable: false },
      { title: 'Picture', fieldName: 'images', sortable: false, isImage: true },
      // { title: 'Cash offer', fieldName: 'cashOffer', sortable: true },
      { title: 'Store credit offer', fieldName: 'storeCreditOffer', sortable: true,dollarSymbol:true },
    ]

    const actions: Action[] = [
      { label: 'Edit', fieldName: 'id', type: 'Edit' },
      { label: 'Delete', fieldName: 'id', type: 'Delete' },
    ]

    return (
      <Table
        columns={columns}
        data={items}
        actions={actions}
        page={0}
        perPage={0}
        setPage={(): void => { }}
        totalRecords={0}
        hidePagination
        handleActions={handleActions}
      />
    )
  }

  const buildAddIEdittemModal = (): JSX.Element => {
    return (
      <AddEditItemModal
        buyoutId={buyoutId ?? ''}
        itemId={showAddEditItemModal.itemId}
        show={showAddEditItemModal.active}
        editMode={showAddEditItemModal.editMode}
        onClose={(): void =>
          setShowAddEditItemModal({ active: false, editMode: false, itemId: undefined })
        }
        setOnLoading={setOnLoading}
        userId={user.id}
      />
    )
  }

  const buildCreateEditModal = (): JSX.Element => {
    return (
      <CreateEditModal
        buyout={buyout}
        editMode={true}
        show={showCreateEditModal}
        setShow={setShowCreateEditModal}
        setOnLoading={setOnLoading}
      />
    )
  }

  const printLabel = () => {
    const componentRef = useRef(null)
    return (
      <>
        <div>
          <ReactToPrint
            trigger={() => <SButton>
              <Icon size={Size.S} className='bi-printer-fill me-2' />
              Print label
            </SButton>}
            content={() => componentRef.current}
          />
          <PrintLabel
            clientName={userName}
            date={buyout.receptionDate}
            periwinkleId={buyout.pwe}
            ref={componentRef}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <SDiv className='d-flex flex-column p-4 pt-3 mb-5 h-100'>
        <BuyoutHeader
          buyout={buyout ?? ({} as Buyout)}
          editMode={true}
          showCreateEditModal={setShowCreateEditModal}
        />
        <div className='d-flex flex-row justify-content-end mb-3'>
          <div>{printLabel()}</div>
          {statusBtn[0]?.description !== '' && statusBtn[0]?.nextStatus !== '' ? (
            <SButtonDanger
              className='ms-4'
              id='changeStatus'
              onClick={(): string | undefined => handleChangeStatus(statusBtn[0]?.nextStatus)}
            >
              <Icon size={Size.S} className='bi-send-fill me-2' />
              {statusBtn[0]?.description}
            </SButtonDanger>
          ) : (
            ''
          )}
          {statusBtn[1]?.description !== '' && statusBtn[1]?.nextStatus !== '' ? (
            <SButtonDanger
              className='ms-4'
              id='changeStatus'
              onClick={(): string | undefined => handleChangeStatus(statusBtn[1]?.nextStatus)}
            >
              <Icon size={Size.S} className='bi-clipboard2-check-fill me-2' />
              {statusBtn[1]?.description}
            </SButtonDanger>
          ) : (
            ''
          )}
        </div>
        <div className='d-flex flex-row justify-content-between align-items-center mt-5'>
          <STitle>Items</STitle>
          <div>
            {buyout?.rejection === undefined &&
              buyout?.status !== buyoutStatus.CUSTOMER_APPROVED &&
              buyout?.status !== buyoutStatus.COMPLETED ? (
                <SIconButtonDark
                  title='Add Item'
                  id='openEditModal'
                  onClick={(): void => setShowAddEditItemModal({ active: true, editMode: false, itemId: undefined })}>
                  <Icon className='bi-plus-square'></Icon>
                </SIconButtonDark>
              ) : (
                ''
              )}
            {buyout?.status !== buyoutStatus.COMPLETED ? (
              <SIconButtonDark className='ms-3' title='Send items to lightspeed' id='openEditModal' onClick={handleCompleteAndItemImport}>
                <Icon className='bi-cloud-arrow-up'></Icon>
              </SIconButtonDark>
            ) : (
              ''
            )}
          </div>
        </div>
        <SHR />

        <div className='mt-4 flex-fill bg-white rounded p-3'>
          {buildItemsTable()}
        </div>
      </SDiv>
      {showAddEditItemModal.active ? buildAddIEdittemModal() : ''}
      {showCreateEditModal ? buildCreateEditModal() : ''}
    </>
  )
}
