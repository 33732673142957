import client from '../api/configs'
import { PaginatedData } from '../common/types'
import { AddCategoryDTO, CategoryLevelDTO, SortOrderDTO, TagDTO } from '../generated'
import { mapToItemList } from '../items/mapper'
import { Item } from '../items/types'
import { mapToTagList, mapToTag } from './mapper'
import { Tag } from './types'

export const GetAll = async (
  offset?: number,
  limit?: number,
  filterKey?: Array<string>,
  filterValue?: Array<string>,
  sortKey?: string,
  sortOrder?: string
): Promise<PaginatedData<Tag>> => {
  const sorting = sortOrder == undefined ? undefined : sortOrder === 'asc' ? SortOrderDTO.ASC : SortOrderDTO.DESC
  const res = await client.getTags({ offset, limit, filterKey, filterValue, sortKey, sortOrder: sorting })
  return {
    data: mapToTagList(res.data ?? []),
    pagination: {
      totalRecords: res.pagination.total_records,
      page: res.pagination.page,
      perPage: res.pagination.per_page,
    },
  }
}

export const SearchTags = async (
  offset: number,
  limit: number,
  searchKey: string,
  searchValue: string,
  filterKey?: Array<string>,
  filterValue?: Array<string>,
): Promise<Tag[]> => {
  const data = await client.searchTags({
    offset,
    limit,
    searchKey,
    searchValue,
    filterKey,
    filterValue,
  })
  return mapToTagList(data)
}

export const GetById = async (id: string): Promise<Tag> => {
  const data = await client.getTag({ id })
  return mapToTag(data)
}

export const GetItems = async (id: string): Promise<Item[]> => {
  const data = await client.getTagItems({ id })
  return mapToItemList(data)
}

export const GetCategories = async (
  searchName?: string,
  parentId?: string,
  offset?: number,
  limit?: number,
): Promise<CategoryLevelDTO[]> => {
  const data = await client.getCategories({ searchName, parentId, offset, limit })
  return data
}

export const Create = async (request: TagDTO): Promise<void> => {
  await client.addTag({ requestBody: request })
}

export const CreateCategory = async (request: AddCategoryDTO): Promise<void> => {
  await client.addCategory({ requestBody: request })
}

export const AddCategory = async (request: AddCategoryDTO): Promise<void> => {
  await client.addCategory({ requestBody: request })
}

export const Patch = async (id: string, request: string): Promise<void> => {
  await client.updateTag({ id, requestBody: request })
}

export const Delete = async (id: string): Promise<void> => {
  await client.deleteTag({ id })
}
