import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Create, Patch } from '../../../../../services/buyouts'
import {
  getUserLabel,
  mapToCreateBuyoutRequest,
  mapToPatchBuyoutRequest,
  mapUserToUserOption,
} from '../../../../../services/buyouts/mapper'
import { formDataSchema, FormData } from './validation/schema'
import 'react-datepicker/dist/react-datepicker.css'
import { Popup } from '../../../../common/modal'
import { GetAll, GetById, SearchUsers } from '../../../../../services/account'
import { User } from '../../../../../services/account/types'
import { Buyout, UserOption } from '../../../../../services/buyouts/types'
import { useSetToken } from '../../../../../hooks/useSetToken'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../../../router/routes'
import AsyncSelect from 'react-select/async'
import { useDispatch } from 'react-redux'
import { dispatchToast } from '../../../../../store/app/slice'
import { DisplayError } from '../../../../common/error'
import { SButton, SButtonDanger, SDatePicker, SLabel } from '../../../../../theme/commonComponents'

type Props = {
  buyout?: Buyout
  show: boolean
  editMode?: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  setOnLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const CreateEditModal = ({
  show,
  setShow,
  setOnLoading,
  buyout,
  editMode = false,
}: Props) => {
  const [user, setUser] = useState<User>()
  const [receptionDate, setReceptionDate] = useState<Date | undefined>(undefined)
  const [userLoaded, setUserLoaded] = useState<boolean>(!editMode)
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver(formDataSchema) })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useSetToken()

  useEffect(() => {
    if (buyout !== undefined) {
      setValue('userId', buyout.user?.id ?? '')
      setValue('receptionDate', buyout.receptionDate ?? new Date())
      setValue('receivedQuantity', buyout.receivedQuantity ?? 0)
      setReceptionDate(buyout.receptionDate)
      getUser(buyout.user?.id ?? '')
    }
  }, [])

  const handleDateChange = (date: Date | null) => {
    if (date !== null) {
      setReceptionDate(date)
      setValue('receptionDate', date)
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  const getUser = (id: string) => {
    try {
      GetById(id)
        .then((data) => {
          setUser(data)
          setUserLoaded(true)
        })
        .catch((error) => {
          console.error(error)
          dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
        })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
        dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
      } else {
        console.log('unexpected error:', error)
        dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
      }
    }
  }

  const loadUserOptions = (inputValue: string) => {
    if (inputValue == '' || inputValue === null || inputValue === undefined) {
      return new Promise<UserOption[]>((resolve) => {
        GetAll(0, 10).then((data) => {
          resolve(mapUserToUserOption(data.data))
        })
      })
    }
    return new Promise<UserOption[]>((resolve) => {
      SearchUsers(0, 10, inputValue).then((data) => {
        resolve(mapUserToUserOption(data))
      })
    })
  }

  const onChange = (option: UserOption | null) => {
    setValue('userId', option?.value ?? '')
  }

  const setUserOption = (): UserOption | undefined => {
    return user !== undefined
      ? ({ label: getUserLabel(user), value: user.id } as UserOption)
      : undefined
  }

  const create = (data: FormData) => {
    const request = mapToCreateBuyoutRequest(data)
    try {
      Create(request)
        .then((data) => {
          dispatch(dispatchToast({ title: 'Buyout created!', type: 'success' }))
          reset()
          setReceptionDate(undefined)
          handleClose()
          navigate(ROUTES.BUYOUTS.VIEW + '/' + data.id)
        })
        .catch((error) => {
          console.error(error)
          dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
        })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
        dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
      } else {
        console.log('unexpected error:', error)
        dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
      }
    }
  }

  const patch = (data: FormData) => {
    const request = mapToPatchBuyoutRequest(buyout ?? ({} as Buyout), data)
    try {
      Patch(buyout?.id ?? '', request)
        .then(() => {
          dispatch(dispatchToast({ title: 'Buyout updated!', type: 'success' }))
          reset()
          setReceptionDate(undefined)
          setOnLoading(true)
          handleClose()
        })
        .catch((error) => {
          console.error(error)
          dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
        })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
        dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
      } else {
        console.log('unexpected error:', error)
        dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
      }
    }
  }

  const onSubmit = (data: FormData) => {
    if (editMode) {
      patch(data)
    } else {
      create(data)
    }
  }

  const renderForm = () => {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          {editMode && (
            <div className='row mb-3'>
              <div className='col-12'>
                <SLabel htmlFor='pwe' className='col-sm-4 col-form-label'>
                  PWE
                </SLabel>
                <div className='col-sm-8'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='pwe'
                    disabled={true}
                    value={buyout?.pwe ?? ''}
                  />
                </div>
              </div>
            </div>
          )}
          <div className='row mb-3'>
            <div className='col-12'>
              <SLabel htmlFor='userList'>User</SLabel>
              {userLoaded && (
                <AsyncSelect
                  id='userList'
                  className='basic-single'
                  classNamePrefix='select'
                  defaultValue={editMode ? setUserOption() : undefined}
                  cacheOptions
                  isClearable
                  defaultOptions
                  loadOptions={loadUserOptions}
                  onChange={(option: UserOption | null) => onChange(option)}
                />
              )}
              {errors?.userId && <p>{errors.userId.message}</p>}
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-12 col-sm-6 col-lg-6'>
              <SLabel htmlFor='receivedQuantity'>Received quantity</SLabel>
              <input
                type='number'
                className='form-control form-control-sm'
                id='receivedQuantity'
                {...register('receivedQuantity')}
              />
              <DisplayError field={errors?.receivedQuantity} />
            </div>
            <div className='col-12 col-sm-6 col-lg-6'>
              <SLabel htmlFor='receptionDate'>Reception date</SLabel>
              <SDatePicker
                className='form-control form-control-sm'
                dateFormat='MM-dd-yyyy'
                selected={receptionDate}
                {...register('receptionDate')}
                onChange={handleDateChange}
              />
              <DisplayError field={errors?.receptionDate} />
            </div>
          </div>
          <div className='d-flex justify-content-end mt-5'>
            <SButtonDanger className='me-3' onClick={handleClose}>
              Close
            </SButtonDanger>
            <SButton type='submit'>{editMode ? 'Update buyout' : ' Create Buyout'}</SButton>
          </div>
        </form>
      </>
    )
  }

  return (
    <>
      <Popup
        show={show}
        backdrop='static'
        title={editMode ? 'Edit buyout' : 'New buyout'}
        handleClose={handleClose}
        content={renderForm()}
      />
    </>
  )
}
