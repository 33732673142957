const theme = {
  shadow: {
    box: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);'
  },
  border: {
    radius: '5px;'
  },
  color: {
    background: '#E8E6D9',
    backgroundPage: '#EDB993',
    periwinkle: '#6487b0',
    periwinkleAlt: '#A29B41',
    primaryButton:{
      color: '#6487B0',
      border: '#7a66b0',
      hover: '#7a66b0',
      focus: '#55487b',
    },
    dangerButton:{
      color: '#7b6046',
      border: '#7b6046',
      hover: '#b08964',
      focus: '#b08964',
    },
    iconButton: '#55487b',
    iconButtonHover: '#6487B0',
    iconButtonFocus: '#6487B0',
    iconButtonHoverDark: '#55487b',
    tableHover: '#E8E6D960',
    label: '#001029'
  },
  text: {
    futura: 'Futura PT',
    playfair: 'Playfair Display',
    formFontSize: '12px'
  },
}

export default theme;