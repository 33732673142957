import * as yup from 'yup'

export const formDataSchema = yup
  .object({
    userId: yup.string().required(),
    receivedQuantity: yup.number()
      .transform((value) => (isNaN(value) || value === null || value === undefined) ? null : value)
      .moreThan(0)
      .required()
      .label('Received quantity'),
    receptionDate: yup.date()
      .transform((value) => (isNaN(value) || value === null || value === undefined) ? null : value)
      .required()
      .label('Reception date')
  })
  .required()

export interface FormData extends yup.InferType<typeof formDataSchema> { }