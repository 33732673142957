import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Create, CreateCategory, GetCategories, Patch } from '../../../../../services/tags'
import {
  mapCategoryToOption,
  mapToCreateCategoryTagRequest,
  mapToCreateTagRequest,
  mapToPatchTagRequest,
} from '../../../../../services/tags/mapper'
import { formDataSchema, FormData } from './validation/schema'
import { Popup } from '../../../../common/modal'
import { SearchCategory, Tag, TagTypes } from '../../../../../services/tags/types'
import { DisplayError } from '../../../../common/error'
import { Option } from '../../../../../services/common/types'
import { useDispatch } from 'react-redux'
import { dispatchToast } from '../../../../../store/app/slice'
import { SButton, SButtonDanger, SLabel } from '../../../../../theme/commonComponents'
import { SASelect } from '../../../../../theme/styledSelect'

type Props = {
  tag?: Tag
  show: boolean
  editMode?: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  setOnLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const CreateEditModal = ({ show, setShow, setOnLoading, tag, editMode = false }: Props) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver(formDataSchema) })
  const [showCategories, setShowCategories] = useState<boolean>(false)
  const [isL2Disable, setIsL2Disable] = useState<boolean>(true)
  const [selectedL2Option, setSelectedL2Option] = useState<Option | null>(null)
  const l1CategoryFieldName = 'l1_category'
  const l2CategoryFieldName = 'l2_category'
  const dispatch = useDispatch()
  useEffect(() => {
    if (tag !== undefined) {
      setValue('name', tag.name)
      setValue('type', tag.type)
    }
  }, [])

  const handleClose = () => {
    setShow(false)
  }

  const loadTagTypesOptions = () => {
    return new Promise<Option[]>((resolve) => {
      const options: Option[] = []
      Object.values(TagTypes).forEach((elem) =>
        options.push({
          label: elem[0] + elem.slice(1),
          value: elem[0] + elem.slice(1),
        }),
      )
      resolve(options)
    })
  }

  const loadDefaultCategoryOptions = (parentId?: string): Promise<Option[]> => {
    return new Promise<Option[]>((resolve) => {
      GetCategories('', parentId ?? '', 0, 10).then((data) => {
        resolve(mapCategoryToOption(data))
      })
    })
  }

  const loadCategoryOptions = (inputValue: string, parentId?: string) => {
    if (inputValue !== '') {
      const obj = JSON.parse(inputValue) as SearchCategory
      const isEmpty = obj.searchValue === '' || obj.searchValue === undefined

      if (!isEmpty) {
        setValue(obj.fieldName as any, obj.searchValue)
        return new Promise<Option[]>((resolve) => {
          GetCategories(obj.searchValue, obj.parentId, 0, 10).then((data) => {
            if (data.length === 0) {
              setValue((obj.fieldName + '_name') as any, obj.searchValue)
              setValue((obj.fieldName + '_id') as any, undefined)
            }
            resolve(mapCategoryToOption(data))
          })
        })
      }
      return loadDefaultCategoryOptions(parentId)
    }
    return loadDefaultCategoryOptions(parentId)
  }

  const onTypeChange = (option: Option | null) => {
    if (option?.value == TagTypes.CATEGORY) {
      setShowCategories(true)
    } else {
      setShowCategories(false)
    }
    setValue('type', option?.value ?? '')
  }

  const onCategoryChange = (option: Option | null, fieldName: string) => {
    if (fieldName == l2CategoryFieldName) {
      setSelectedL2Option(option)
    }

    if (fieldName == l1CategoryFieldName) {
      if (option !== null && option.value !== undefined && option.value !== '') {
        setIsL2Disable(false)
      } else {
        setIsL2Disable(true)
        setSelectedL2Option(null)
        setValue((l2CategoryFieldName + '_id') as any, undefined)
        setValue((l2CategoryFieldName + '_name') as any, undefined)
      }
    }

    if (option?.__isNew__ && option?.label === option?.value) {
      setValue((fieldName + '_id') as any, undefined)
      setValue((fieldName + '_name') as any, option?.label ?? '')
    } else {
      setValue((fieldName + '_id') as any, option?.value ?? '')
      setValue((fieldName + '_name') as any, option?.label ?? '')
    }
  }

  const createTag = (data: FormData) => {
    try {
      if (data.type != TagTypes.CATEGORY) {
        const requestTagCreation = mapToCreateTagRequest(data)
        Create(requestTagCreation)
          .then(() => {
            dispatch(dispatchToast({ title: 'Tag created!', type: 'success' }))
            reset()
            handleClose()
            setOnLoading(true)
          })
          .catch((error) => {
            console.error(error)
            dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
          })
      } else {
        const requestCategoryTagCreation = mapToCreateCategoryTagRequest(data)
        CreateCategory(requestCategoryTagCreation)
          .then(() => {
            dispatch(dispatchToast({ title: 'Tag created!', type: 'success' }))
            reset()
            handleClose()
            setOnLoading(true)
          })
          .catch((error) => {
            console.error(error)
            dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
          })
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
        dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
      } else {
        console.log('unexpected error:', error)
        dispatch(dispatchToast({ title: 'Something went wrong!', type: 'error' }))
      }
    }
  }

  const pathTag = (data: FormData) => {
    const request = mapToPatchTagRequest(tag ?? ({} as Tag), data)
    try {
      Patch(tag?.id ?? '', request).then(() => {
        console.log('Success')
        dispatch(dispatchToast({ title: 'Tag updated!', type: 'success' }))
        reset()
        handleClose()
        setOnLoading(true)
      })
    } catch (error) {
      dispatch(dispatchToast({ title: 'Something wrong happened!', type: 'error' }))
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
        return error.message
      } else {
        console.log('unexpected error:', error)
        return 'An unexpected error occured'
      }
    }
  }

  const onSubmit = (data: FormData) => {
    if (editMode) {
      pathTag(data)
    } else {
      createTag(data)
    }
  }
  
  const renderForm = () => {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='row mb-3'>
            <div className='col'>
              <SLabel htmlFor='name'>
                Name
              </SLabel>
              <input type='text' className='form-control form-control-sm' id='name' {...register('name')} />
              <DisplayError field={errors?.name} />
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col'>
              <SLabel htmlFor='type'>
                Type
              </SLabel>
              <SASelect
                defaultOptions={true}
                loadOptions={loadTagTypesOptions}
                onChange={(option: Option | null) => onTypeChange(option)}
              />
              <DisplayError field={errors?.type} />
            </div>
          </div>

          {showCategories && (
            <div className='row mb-3'>
              <div className='col-sm-6 col-12 col-xl-6'>
                <SLabel htmlFor='type'>
                  Level 1
                </SLabel>
                <SASelect
                  defaultOptions={true}
                  loadOptions={(inputValue: string) => loadCategoryOptions(inputValue)}
                  onChange={(option: Option | null) =>
                    onCategoryChange(option, l1CategoryFieldName)
                  }
                  onInputChange={(newValue: string) =>
                    JSON.stringify({ searchValue: newValue, fieldName: l1CategoryFieldName })
                  }
                />
              </div>

              <div className='col-sm-6 col-12 col-xl-6'>
                <SLabel htmlFor='type'>
                  Level 2
                </SLabel>
                {isL2Disable ?
                  (
                    <SASelect
                      isDisabled={isL2Disable}
                    />
                  ) :
                  (
                    <SASelect
                      defaultOptions={true}
                      key={JSON.stringify(isL2Disable)}
                      isDisabled={isL2Disable}
                      value={selectedL2Option}
                      loadOptions={(inputValue: string) =>
                        loadCategoryOptions(inputValue, getValues((l1CategoryFieldName + '_id') as any))
                      }
                      onChange={(option: Option | null) =>
                        onCategoryChange(option, l2CategoryFieldName)
                      }
                      onInputChange={(newValue: string) =>
                        JSON.stringify({
                          searchValue: newValue,
                          parentId: getValues((l1CategoryFieldName + '_id') as any),
                          fieldName: l2CategoryFieldName,
                        })
                      }
                    />
                  )}
              </div>
            </div>
          )}
          <div className='d-flex justify-content-end mt-5'>
            <SButtonDanger className='me-3' onClick={handleClose}>
              Close
            </SButtonDanger>
            <SButton type='submit'>
              Add tag
            </SButton>
          </div>
        </form >
      </>
    )
  }

  return (
    <>
      <Popup
        backdrop='static'
        show={show}
        title={editMode ? 'Edit tag' : 'New tag'}
        handleClose={handleClose}
        content={renderForm()}
      />
    </>
  )
}
