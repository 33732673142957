import axios from 'axios'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../../router/routes'
import { GetAll } from '../../../../services/tags'
import { Tag } from '../../../../services/tags/types'
import { Table } from '../../../common/table'
import { Action, Column } from '../../../common/table/types'
import { CreateEditModal } from '../modals/create'
import { SDiv, STitle, SIconButtonDark, Icon, SHR } from '../../../../theme/commonComponents'

export const List = (): JSX.Element => {
  const [data, setData] = useState<Tag[]>([])
  const [page, setPage] = useState<number>(1)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [sort, setSort] = useState<{ sortBy: string; orderBy: string }>({
    sortBy: 'id',
    orderBy: 'asc',
  })
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [onLoading, setOnLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const perPage = 10
  useEffect((): void => {
    try {
      GetAll((page - 1) * perPage, perPage, [], [], sort.sortBy, sort.orderBy).then((data) => {
        setData(data.data)
        setTotalRecords(data.pagination.totalRecords)
        setOnLoading(false)
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
      } else {
        console.log('unexpected error:', error)
      }
    }
  }, [page, perPage, sort, onLoading])

  const handleSort = (sortBy: string, orderBy: string): void => {
    setSort({ sortBy: sortBy, orderBy: orderBy })
  }

  const handleOnView = (id: string): void => {
    navigate(ROUTES.TAGS.VIEW + '/' + id)
  }

  const handleOnCreate = (): void => {
    setShowCreateModal(true)
  }

  const handleActions = (type: string, id: string): void => {
    switch (type) {
    case 'View':
      handleOnView(id)
      break
    default:
      break
    }
  }

  const buildTable = (): JSX.Element => {
    const columns: Column[] = [
      { title: 'Id', fieldName: 'id', hidden: true, sortable: false },
      { title: 'Name', fieldName: 'name', sortable: true },
      { title: 'Type', fieldName: 'type', sortable: true },
    ]

    const actions: Action[] = [{ label: 'View', fieldName: 'id', type: 'View' }]

    return (
      <Table
        columns={columns}
        data={data}
        actions={actions}
        page={page}
        perPage={perPage}
        setPage={setPage}
        totalRecords={totalRecords}
        handleActions={handleActions}
        handleSort={handleSort}
      />
    )
  }

  const buildModal = (): JSX.Element => {
    return (
      <CreateEditModal
        show={showCreateModal}
        setShow={setShowCreateModal}
        setOnLoading={setOnLoading}
      />
    )
  }

  return (
    <>
      <SDiv className='d-flex flex-column p-4 pt-3 mb-5 h-100'>
        <div className='row mb-3'>
          <div className='col-12'>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <STitle>Tags</STitle>
              <SIconButtonDark title='Add Tag' className='btn p-0 m-0' onClick={() => handleOnCreate()}>
                <Icon className='bi-plus-square'></Icon>
              </SIconButtonDark>
            </div>
            <SHR />
          </div>
        </div>
        <div className='mt-4 flex-fill bg-white rounded p-3'>
          {buildTable()}
        </div>
        {showCreateModal ? buildModal() : ''}
      </SDiv>
    </>

  // <div className='py-5'>
  //   <div>
  //     <h1 className='text-muted'>Tags</h1>
  //   </div>
  //     <button className='btn btn-primary' onClick={(): void => handleOnCreate()}>
  //       Add tag
  //     </button>
  //   <div className='py-3'>{buildTable()}</div>
  //   {showCreateModal ? buildModal() : ''}
  // </div>
  )
}
