import { ChildDTO } from '../generated'
import { Child } from './types'
import { FormData as ChildFormData } from '../../components/modules/account/modals/addChild/validation/schema';

export const mapToChildList = (data: ChildDTO[]): Child[] => {
  let list: Child[] = []

  data.forEach((element) => {
    list.push(mapToChild(element))
  })

  return list
}

export const mapToChild = (data: ChildDTO): Child => {
  return ({
    id: data.id ?? '',
    firstName: data.first_name ?? '',
    userId: data.user_id,
    birthday: data.birthday ? new Date(data.birthday) : new Date(),
  })
}

export const mapFormDataToChild = (data: ChildFormData): Child => {
  return {
    id: '',
    userId: data.userId,
    firstName: data.firstName,
    birthday: data.birthday
  }
}

export const mapFormDataToChildDTO = (data: ChildFormData): ChildDTO => {
  return {
    user_id: data.userId,
    first_name: data.firstName,
    birthday: data.birthday.toUTCString(),
  }
}