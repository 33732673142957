import { FormData } from '../../components/modules/account/modals/create/validation/schema'
import { FormData as RegistrationFormDate } from '../../components/modules/account/registration/validation/schema'
import { UserDTO } from '../generated'
import { User } from './types'
import * as jsonpatch from 'fast-json-patch'

export const mapToUser = (data: UserDTO): User => {
  return {
    id: data.id ?? '',
    firstName: data.first_name ?? '',
    lastName: data.last_name ?? '',
    email: data.email ?? '',
    phone: data.phone ?? '',
    address: data.address ?? '',
    city: data.city ?? '',
    state: data.state ?? '',
    zipCode: data.zip_code ?? '',
  }
}

export const mapToUserList = (data: UserDTO[]): User[] => {
  let list: User[] = []

  data.forEach((element) => {
    list.push(mapToUser(element))
  })

  return list
}

export const mapToCreateUserRequest = (data: FormData): UserDTO => {
  return {
    first_name: data.firstName,
    last_name: data.lastName,
    address: data.address,
    email: data.email,
    phone: data.phone,
  }
}

export const mapToRegisterUserRequest = (data: RegistrationFormDate): UserDTO => {
  return {
    external_id: data.externalId,
    first_name: data.firstName,
    last_name: data.lastName,
    address: data.address,
    city: data.city,
    state: data.state,
    zip_code: data.zipCode,
    email: data.email,
    phone: data.phone,
    date_of_birth: new Date(data.dateOfBirth).toUTCString(),
    driver_license_expiration_date:  new Date(data.expirationDate).toUTCString(),
    driver_license_issuer: data.issuer,
    driver_license_number: data.driverLicenseNumber,
    paypal_email: data.paypalEmail,
  }
}

export const mapToPatchUserRequest = (user: User, data: FormData): Object => {
  var observer = jsonpatch.observe<User>(user)

  if (user.firstName != data.firstName) {
    user.firstName = data.firstName
  }

  if (user.lastName != data.lastName) {
    user.lastName = data.lastName
  }

  if (user.address != data.address) {
    user.address = data.address
  }

  if (user.city != data.city) {
    user.city = data.city
  }

  if (user.state != data.state) {
    user.state = data.state
  }

  if (user.zipCode != data.zipCode) {
    user.zipCode = data.zipCode
  }

  if (user.phone != data.phone) {
    user.phone = data.phone
  }

  var patch = jsonpatch.generate(observer)
  return JSON.stringify(patch)
}
