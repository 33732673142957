import { Outlet } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import { GetAll } from '../../../services/account'
import { updateUser } from '../../../store/app/slice'
import { Sidemenu } from '../../common/sidemenu'
import jwt_decode from 'jwt-decode'
import { Token } from '../../../services/api/types'
import { Autho0Config } from '../../../config'
import { Registration } from '../account/registration'
import { Toast } from '../../common/toast'
import Loading from '../../common/generic/loading'

export const Home = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0()
  const [showRegistrationForm, setShowRegistrationForm] = useState<boolean>(false)
  const [ showLoading, setShowLoading] = useState<boolean>(true)
  const dispatch = useDispatch()
  
  useEffect(() => {
    if (isAuthenticated) {
      const interval = setInterval(() => {
        getAccessTokenSilently({
          cacheMode:'off',
          authorizationParams: {
            audience: Autho0Config.audience,
          },
        }).then((accessToken) => {
          var decoded = jwt_decode<Token>(accessToken)
          if (decoded.periwinklefox_roles.length == 0) {
            if (showLoading == false) {
              setShowLoading(true)
            }
          } else {
            setShowLoading(false)
            GetAll(undefined, undefined, ['Email'], [user?.email ?? '']).then((res) => {
              if (res.data === undefined || res.data.length === 0) {
                setShowRegistrationForm(true)
              } else {
                dispatch(
                  updateUser({
                    id: res.data[0].id,
                    name: user?.name,
                    email: user?.email,
                    address: user?.address,
                    gender: user?.gender,
                    locale: user?.locale,
                    phone_number: user?.phone_number,
                    picture: user?.picture,
                    profile: user?.profile,
                    zoneinfo: user?.zoneinfo,
                    isAdmin: decoded.periwinklefox_roles.indexOf('Admin') > -1,
                  }),
                )
              }
            })
            clearInterval(interval)
          }
        })
      }, 1000)
    }
  }, [isAuthenticated, showRegistrationForm, showLoading])

  const buildRegistrationModal = () => {
    return <Registration setShow={setShowRegistrationForm} show={showRegistrationForm} />
  }

  return (
    <>
      {showLoading ? (
        <Loading />
      ) : (
        <>
          <div className='container-fluid vh-100'>
            <div className='row vh-100'>
              <div className='col-lg-2 col-md-4 col-sm-12'>
                <Sidemenu />
              </div>
              <div className='p-0 col-lg-10 col-md-8 col-sm-12 container-fluid'>
                <Outlet />
              </div>
            </div>
          </div>
          {showRegistrationForm ? buildRegistrationModal() : ''}
        </>
      )}
      <Toast />
    </>
  )
}
