import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { setToken } from '../services/api/configs';

export const useSetToken = () : void => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setToken(getAccessTokenSilently)
  }, [getAccessTokenSilently])
}