import { StyleWrap } from './styles'
import Countdown from 'react-countdown'
import { useLogout } from '../../../../../hooks/useLogout'

type Props = {
  show: boolean
}

export const SuccessRegistrationModal = ({ show }: Props) => {
  const logout = useLogout()
  const logo = require('./../../../../../assets/logo.png')

  return (
    <StyleWrap>
      <div
        id='successRegistrationModal'
        className={`modal fade ${show ? 'show' : ''}`}
        style={{ display: show ? 'block' : '' }}
        aria-modal={show}
      >
        <div className='modal-dialog modal-confirm'>
          <div className='modal-content'>
            <div className='modal-header'>
              <div className='icon-box'>
                <img src={logo} style={{ width: '6rem' }} />
              </div>
              <h4 className='modal-title w-100'>Periwinkle Fox</h4>
            </div>
            <div className='modal-body'>
              <p className='text-center'>Thank you for signing up!</p>
              <p className='text-center'>
                You will be redirected to the login page in{' '}
                {
                  <Countdown
                    date={Date.now() + 5000}
                    onComplete={logout}
                    renderer={(props) => <>{props.seconds}</>}
                  />
                }{' '}
                seconds.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </StyleWrap>
  )
}
